<template>
  <div>
    <div v-if="loading" class="flex items-center justify-center h-full">
      <loading-spinner diameter="64px" />
    </div>

    <alert-badge
      v-else-if="error.title"
      isDanger
      :title="error.title"
      :body="error.body"
      @dismissed="
        () => {
          error.title = '';
          error.body = '';
        }
      "
    />

    <div class="m-3" style="height:90vh">
      <div class="flex flex-col items-center content-center gap-2 md:flex-row">
        <el-select class="input-label" v-model="location">
          <el-option
            v-for="(loc, i) in locations"
            :key="i"
            :label="$ctx.locale === 'en' ? loc.enName : loc.arName"
            :value="loc.id"
          >
          </el-option>
        </el-select>

        <el-date-picker
          v-model="period"
          type="daterange"
          range-separator="to"
          start-placeholder="Start date"
          end-placeholder="End date"
        >
        </el-date-picker>
      </div>

      <div
        v-if="rowData.length === 0"
        class="flex flex-col justify-center items-center h-5/6  w-full"
      >
        <img src="@/assets/emptyFolder.png" alt="empty folder" width="250" />
        <h1 class="uppercase">{{ $t("views.reports.emptyState") }}</h1>
      </div>

      <bar-chart
        v-else
        title="sales by category"
        :xAxis="categories"
        :chartData="totals"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import Location from "@/models/company/Location";
import BarChart from "@/components/charts/BarChart.vue";
import { Dinero as DineroType } from "dinero.js";
import { getPath } from "./utils";
import dayjs from "dayjs";

class SaleByCategory {
  static from(json: Record<string, unknown> | SaleByCategory) {
    return Object.assign(new SaleByCategory(), json);
  }

  static ENDPOINT = "reports/sales/sales-by-category";

  categoryName?: string;
  categoryID?: string;
  totalSoldAmount?: number;
  totalSoldCurrency?: string;
  totalSoldPrecision?: number;
  totalSoldDinero?: DineroType;
}

export default defineComponent({
  name: "sales-by-category",
  components: {
    LoadingSpinner,
    AlertBadge,
    BarChart
  },
  data() {
    const rowData: SaleByCategory[] = [];
    const categories: string[] = [];
    const totals: number[] = [];
    return {
      rowData,
      error: {
        title: "",
        body: ""
      },
      loading: false,
      locations: new Array<Location>(),
      location: "",
      categories,
      period: [
        dayjs()
          .startOf("month")
          .format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD")
      ], // the default period is from the first day of the month to the current day
      totals
    };
  },
  async beforeMount() {
    const catResp = await this.$http.get<Location[]>(Location.ENDPOINT);
    this.locations = catResp.map(cat => Location.from(cat));
    this.updateRowData();
  },
  watch: {
    period: {
      handler: function() {
        this.updateRowData();
      }
    },
    location: {
      handler: function() {
        this.updateRowData();
      }
    }
  },
  methods: {
    async updateRowData() {
      try {
        const salesByCategoryResp = await this.$http.get<SaleByCategory[]>(
          getPath(SaleByCategory.ENDPOINT, this.location, this.period)
        );
        this.rowData = salesByCategoryResp.map(saleByCategory =>
          SaleByCategory.from(saleByCategory)
        );

        this.rowData.forEach(category => {
          this.categories.push(
            category.categoryName ? category.categoryName : ""
          );
          this.totals.push(
            category.totalSoldAmount ? category.totalSoldAmount : 0
          );
        });
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        });
      }
      this.loading = false;
    }
  }
});
</script>

<style>
.el-date-editor .el-range-separator {
  width: auto !important;
}
</style>
