
import { defineComponent } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import Location from "@/models/company/Location";
import { ColDef, GridOptions } from "ag-grid-community";
import { AgGridVue } from "ag-grid-vue3";
import { useI18n } from "vue-i18n";
import { getPath } from "./utils";
import dayjs from "dayjs";

class LeastSoldItem {
  static from(json: Record<string, unknown> | LeastSoldItem) {
    return Object.assign(new LeastSoldItem(), json);
  }

  static ENDPOINT = "/reports/sales/least-sold-items";
  itemName = "";
  itemID = "";
  quantity = 0;
}
export default defineComponent({
  components: {
    LoadingSpinner,
    AlertBadge,
    AgGridVue
  },

  data() {
    const columnDefs: ColDef[] = [];
    const rowData: LeastSoldItem[] = [];
    const gridOptions: GridOptions = {};
    const defaultColDef: ColDef = {};
    const messages = {
      en: {
        startDate: "Start Date",
        endDate: "End Date",
        name: "Name",
        quantity: "Quantity"
      },
      ar: {
        startDate: "Start Date",
        endDate: "End Date",
        name: "Name",
        quantity: "Quantity"
      }
    };

    const { t } = useI18n({
      messages
    });
    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,
      error: {
        title: "",
        body: ""
      },
      loading: false,
      locations: new Array<Location>(),
      location: "",
      period: [
        dayjs()
          .startOf("month")
          .format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD")
      ], // the default period is from the first day of the month to the current day
      t
    };
  },

  async beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 100,
      cellStyle: { textAlign: "center" },
      flex: 1
    };
    this.columnDefs = [
      {
        headerName: this.t("name"),
        field: "itemName",
        checkboxSelection: true,
        autoHeight: true,
        cellStyle: { textAlign: "start" },
        headerCheckboxSelection: true,
        minWidth: 500
      },
      {
        headerName: this.t("quantity"),
        field: "quantity",
        type: "numericColumn",
        filter: "agNumberColumnFilter",
        cellStyle: { textAlign: "center" }
      }
    ];
    const locationRes = await this.$http.get<Location[]>(Location.ENDPOINT);
    this.locations = locationRes.map(cat => Location.from(cat));
  },

  watch: {
    period: {
      handler: function() {
        this.updateRowData();
      }
    },
    location: {
      handler: function() {
        this.updateRowData();
      }
    }
  },

  methods: {
    async updateRowData() {
      this.gridOptions.api?.showLoadingOverlay();
      try {
        const leastSoldItemsRes = await this.$http.get<LeastSoldItem[]>(
          getPath(LeastSoldItem.ENDPOINT, this.location, this.period)
        );
        this.rowData = leastSoldItemsRes.map(leastSoldItem =>
          LeastSoldItem.from(leastSoldItem)
        );
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        });
      }

      this.gridOptions.api?.hideOverlay();
    },

    async onGridReady() {
      await this.updateRowData();
    }
  }
});
