
import { defineComponent } from "vue";

export default defineComponent({
  setup(props) {
    const chartOptions = {
      chart: {
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      colors: ["#0077B6"],
      xaxis: {
        categories: props.xAxis
      }
    };

    return { chartOptions };
  },

  props: {
    chartData: {
      required: true,
      type: Object as () => ApexAxisChartSeries
    },
    chartTitle: String,
    xAxis: {
      type: Object as () => string[]
    }
  }
});
