
import { defineComponent } from "vue";
import { RouteLocationRaw } from "vue-router";

export default defineComponent({
  methods: {
    onClicked() {
      this.$router.push(this.path);
    },
  },
  props: {
    linkTitle: String,
    path: {
      required: true,
      type: String
    }
  }
});
