
import { defineComponent } from "vue";

export default defineComponent({
  name: "bar-chart",
  props: {
    xAxis: {
      type: Array,
      default: () => []
    },
    chartData: {
      type: Array,
      default: () => []
    },
    title: {
      type: String
    }
  }
});
