
import { defineComponent } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import { ColDef, GridOptions } from "ag-grid-community";
import { AgGridVue } from "ag-grid-vue3";
import { useI18n } from "vue-i18n";
import Dinero from "dinero.js";
import { formatDinero } from "@/utils/money";
import dayjs from "dayjs";
import Location from "@/models/company/Location";
import { useRoute } from "vue-router";
import Customer from "@/models/customer/Customer";
import HorizontalButton from "@/components/HorizontalButton.vue";

class CustomerBalance {
  static from(json: Record<string, unknown> | CustomerBalance) {
    return Object.assign(new CustomerBalance(), json);
  }
  static ENDPOINT = "reports/customer/customer-balance";
  customerID?: string;
  customer?: Customer;
  balanceAmount?: number;
  currency?: "IQD" | "USD";
  precision = 0;
}

export default defineComponent({
  components: {
    LoadingSpinner,
    AlertBadge,
    AgGridVue,
    HorizontalButton
  },

  data() {
    const router = useRoute();
    const columnDefs: ColDef[] = [];
    const rowData: CustomerBalance[] = [];
    const gridOptions: GridOptions = {};
    const defaultColDef: ColDef = {};
    const messages = {
      en: {
        startDate: "Start Date",
        endDate: "End Date",
        customer: "Customer",
        balance: "Balance",
        generateReport: "Generate Report"
      },
      ar: {
        startDate: "Start Date",
        endDate: "End Date",
        customer: "Customer",
        balance: "Balance",
        generateReport: "Generate Report"
      }
    };

    const { t } = useI18n({
      messages
    });
    return {
      router,
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,
      error: {
        title: "",
        body: ""
      },
      loading: false,
      t,
      locations: new Array<Location>(),
      location: "",
      period: [
        dayjs()
          .startOf("month")
          .format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD")
      ] // the default period is from the first day of the month to the current day
    };
  },

  async beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 100,
      cellStyle: { textAlign: "center" },
      flex: 1
    };
    this.columnDefs = [
      {
        headerName: this.t("customer"),
        field: "customer.contactDisplayName",
        checkboxSelection: true,
        autoHeight: true,
        cellStyle: { textAlign: "start" },
        headerCheckboxSelection: true
      },
      {
        headerName: this.t("balance"),
        field: "balanceAmount",
        type: "numericColumn",
        filter: "agNumberColumnFilter",
        cellStyle: { textAlign: "center" },
        valueFormatter: params => {
          const data = params.data as CustomerBalance;
          return formatDinero(
            Dinero({
              amount: data.balanceAmount,
              currency: data.currency
            })
          );
        }
      }
    ];
    const locationRes = await this.$http.get<Location[]>(Location.ENDPOINT);
    this.locations = locationRes.map(loc => Location.from(loc));
  },

  mounted() {
    this.gridOptions.onCellDoubleClicked = event => {
      // this block should be used when the updated customer invoices PR is merged
      // this.$router.push(
      //   this.$Route.ACCOUNTING_CUSTOMER_INVOICES.replace(
      //     ":id",
      //     event.data.customerID
      //   )
      // );
    };
  },

  methods: {
    async updateRowData() {
      this.gridOptions.api?.showLoadingOverlay();
      try {
        const res = await this.$http.get<CustomerBalance[]>(
          CustomerBalance.ENDPOINT
        );

        this.rowData = res.map(customerBalance =>
          CustomerBalance.from(customerBalance)
        );
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        });
      }

      this.gridOptions.api?.hideOverlay();
    },

    async onGridReady() {
      await this.updateRowData();
    }
  }
});
