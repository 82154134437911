<template>
  <div>
    <div v-if="loading" class="flex items-center justify-center h-full">
      <loading-spinner diameter="64px" />
    </div>

    <alert-badge
      v-else-if="error.title"
      isDanger
      :title="error.title"
      :body="error.body"
      @dismissed="
        () => {
          error.title = '';
          error.body = '';
        }
      "
    />

    <div class="m-6">
      <el-row :gutter="50">
        <el-col :lg="12">
          <div class="section-container">
            <div class="section-title py-2 mt-6 rounded">
              <h2 class="text-center p-0">
                <font-awesome-icon icon="dollar-sign"> </font-awesome-icon>
                {{ this.$t("views.sales.sales") }}
              </h2>
            </div>
            <div class="buttons-container mt-3">
              <el-row :gutter="15">
                <el-col
                  v-for="(link, i) in tabs.sales"
                  :key="i"
                  :md="8"
                  :xs="24"
                >
                  <reports-link :path="link.path" :linkTitle="link.title" />
                </el-col>
              </el-row>
            </div>
          </div>

          <div class="section-title py-2 mt-6 rounded">
            <h2 class="text-center p-0">
              <font-awesome-icon icon="warehouse"> </font-awesome-icon>
              {{ this.$t("views.inventory.inventory") }}
            </h2>
          </div>
          <div class="buttons-container mt-3">
            <el-row :gutter="15">
              <el-col
                v-for="(link, i) in tabs.inventory"
                :key="i"
                :md="8"
                :xs="24"
              >
                <reports-link :path="link.path" :linkTitle="link.title" />
              </el-col>
            </el-row>
          </div>

          <div class="section-title py-2 mt-6 rounded">
            <h2 class="text-center p-0">
              <font-awesome-icon icon="users"> </font-awesome-icon>
              {{ this.$t("views.customers.customers") }}
            </h2>
          </div>
          <div class="buttons-container mt-3">
            <el-row :gutter="15">
              <el-col
                v-for="(link, i) in tabs.customers"
                :key="i"
                :md="8"
                :xs="24"
              >
                <reports-link :path="link.path" :linkTitle="link.title" />
              </el-col>
            </el-row>
          </div>
        </el-col>

        <el-col :lg="12">
          <div class="section-title py-2 mt-6 rounded">
            <h2 class="text-center p-0">
              <font-awesome-icon icon="calculator"> </font-awesome-icon>
              {{ this.$t("views.accounting.accounting") }}
            </h2>
          </div>
          <div class="buttons-container mt-3">
            <el-row :gutter="15">
              <el-col
                v-for="(link, i) in tabs.accounting"
                :key="i"
                :md="8"
                :xs="24"
              >
                <reports-link :path="link.path" :linkTitle="link.title" />
              </el-col>
            </el-row>
          </div>

          <div class="section-title py-2 mt-6 rounded">
            <h2 class="text-center p-0">
              {{ this.$t("views.hr.hr") }}
            </h2>
          </div>
          <div class="buttons-container mt-3">
            <el-row :gutter="15">
              <el-col
                v-for="(link, i) in tabs.employees"
                :key="i"
                :md="8"
                :xs="24"
              >
                <reports-link :path="link.path" :linkTitle="link.title" />
              </el-col>
            </el-row>
          </div>

          <div class="section-title py-2 mt-6 rounded">
            <h2 class="text-center p-0">
              <font-awesome-icon icon="users"> </font-awesome-icon>
              {{ this.$t("views.vendors.vendors") }}
            </h2>
          </div>
          <div class="buttons-container mt-3">
            <el-row :gutter="15">
              <el-col
                v-for="(link, i) in tabs.vendors"
                :key="i"
                :md="8"
                :xs="24"
              >
                <reports-link :path="link.path" :linkTitle="link.title" />
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import ReportsLink from "@/components/ReportsLink.vue";
import { Route } from "@/router/routes";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    LoadingSpinner,
    AlertBadge,
    ReportsLink
  },

  data() {
    const chartData: ApexAxisChartSeries[] = [];
    const messages = {
      en: {
        sections: {
          sales: {
            topSoldItems: "Top Sold Items",
            leastSoldItems: "Least Sold Items",
            salesByItem: "Sales By Item",
            salesByCategory: "Sales By Category",
            salesSummary: "Sales Summary",
            registerClosing: "Register Closing",
            endOfDay: "End of Day",
            salesComparison: "Sales Comparison",
            salesHeatmap: "Sales Heatmap"
          },
          inventory: {
            quantity: "Quantity Report",
            inventoryValue: "Inventory Value"
          },
          customers: {
            topCustomers: "Top Customers"
          },
          accounting: {
            profitAndLoss: "Profit and Loss",
            vendorsBalances: "Vendors Balances",
            customersBalances: "Customers Balances"
          },
          employees: {},
          vendors: {
            topVendors: "Top Vendors"
          }
        }
      },
      ar: {
        sections: {
          sales: {
            topSoldItems: "أعلى المنتجات مبيعا ",
            leastSoldItems: "أقل المنتجات مبيعا ",
            salesByItem: "مبيعات منتج محدد",
            salesByCategory: "مبيعات صنف محدد",
            salesSummary: "ملخص المبيعات",
            endOfDay: "نهاية اليوم",
            salesComparison: "مقارنة المبيعات",
            salesHeatmap: "خارطة المبيعات",
            registerClosing: "اغلاق الصندوق"
          },
          inventory: {
            quantity: "كميات المواد",
            inventoryValue: "قيمة البضاعة"
          },
          customers: {
            topCustomers: "أفضل الزبائن"
          },
          accounting: {
            profitAndLoss: "الربح والخسارة",
            vendorsBalances: "ميزانية الموردين",
            customersBalances: "ميزانية الزبائن"
          },
          employees: {},
          vendors: {
            topVendors: "أفضل الموردين"
          }
        }
      }
    };

    const { t } = useI18n({
      messages
    });

    const tabs = {
      sales: [
        {
          title: t("sections.sales.salesByItem"),
          path: Route.REPORTS_SALES_SALES_BY_ITEM
        },
        {
          title: "sales by category",
          path: Route.REPORTS_SALES_SALES_BY_CATEGORY
        },
        {
          title: t("sections.sales.topSoldItems"),
          path: Route.REPORTS_SALES_TOP_SOLD_ITEMS
        },
        {
          title: t("sections.sales.leastSoldItems"),
          path: Route.REPORTS_SALES_LEAST_SOLD_ITEMS
        },
        {
          title: t("sections.sales.salesByCategory"),
          path: Route.REPORTS_SALES_SALES_BY_CATEGORY
        },
        {
          title: t("sections.sales.salesSummary"),
          path: Route.REPORTS_SALES_SALES_SUMMARY
        },
        {
          title: t("sections.sales.registerClosing"),
          path: Route.REPORTS_SALES_SALES_REGISTER_CLOSING_REPORT
        },
        {
          title: t("sections.sales.endOfDay"),
          path: Route.REPORTS_SALES_END_OF_DAY
        },
        {
          title: t("sections.sales.salesComparison"),
          path: Route.REPORTS_SALES_SALES_COMPARISON
        },
        {
          title: t("sections.sales.salesHeatmap"),
          path: Route.REPORTS_SALES_SALES_HEATMAP
        }
      ],
      inventory: [
        {
          title: t("sections.inventory.quantity"),
          path: Route.REPORTS_INVENTORY_QUANTITY
        },
        {
          title: t("sections.inventory.inventoryValue"),
          path: Route.REPORTS_INVENTORY_INVENTORY_VALUE
        }
      ],
      customers: [
        {
          title: t("sections.customers.topCustomers"),
          path: Route.REPORTS_CUSTOMERS_TOP_CUSTOMERS
        }
      ],
      accounting: [
        {
          title: t("sections.accounting.profitAndLoss"),
          path: Route.REPORTS_ACCOUNTING_PROFIT_AND_LOSS
        },
        {
          title: t("sections.accounting.vendorsBalances"),
          path: Route.REPORTS_VENDORS_VENDORS_BALANCES
        },
        {
          title: t("sections.accounting.customersBalances"),
          path: Route.REPORTS_ACCOUNTING_CUSTOMERS_BALANCES
        }
      ],
      employees: [
        {
          title: "users",
          path: "#"
        },
        {
          title: "attendance",
          path: "#"
        },
        {
          title: "time off requests",
          path: "#"
        },
        {
          title: "employees ranking",
          path: "#"
        }
      ],
      vendors: [
        {
          title: t("sections.vendors.topVendors"),
          path: Route.REPORTS_VENDORS_TOP_VENDORS
        }
      ]
    };

    return {
      error: {
        title: "",
        body: ""
      },
      loading: false,
      chartData,
      tabs
    };
  },

  async beforeMount() {
    this.chartData = ([
      {
        name: "Net Profit",
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
      },
      {
        name: "Revenue",
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
      },
      {
        name: "Free Cash Flow",
        data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
      }
    ] as unknown) as ApexAxisChartSeries[];
  },
  methods: {}
});
</script>

<style scoped>
.section-title {
  background: var(--tertiary-bg-color);
  color: var(--tertiary-text-color);
}
</style>
