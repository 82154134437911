<template>
  <div>
    <div v-if="loading" class="flex items-center justify-center h-full">
      <loading-spinner diameter="64px" />
    </div>

    <alert-badge
      v-else-if="error.title"
      isDanger
      :title="error.title"
      :body="error.body"
      @dismissed="
        () => {
          error.title = '';
          error.body = '';
        }
      "
    />

    <div class="m-3" style="height:90vh">
      <div
        class="flex flex-col items-center content-center gap-2 md:flex-row w-full"
      >
        <el-select
          v-model="selectedChartMode"
          placeholder="Select the Mode"
          size="large"
        >
          <el-option
            v-for="item in chartModes"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <el-select class="input-label" v-model="location">
          <el-option
            v-for="(loc, i) in locations"
            :key="i"
            :label="$ctx.locale === 'en' ? loc.enName : loc.arName"
            :value="loc.id"
          >
          </el-option>
        </el-select>

        <el-date-picker
          v-model="period"
          type="daterange"
          range-separator="to"
          start-placeholder="Start date"
          end-placeholder="End date"
        >
        </el-date-picker>
        <div class="w-1/4">
          <horizontal-button
            :title="t('generateReport')"
            @click="updateRowData()"
          />
        </div>
      </div>

      <div
        v-if="chartData === undefined"
        class="flex flex-col justify-center items-center h-5/6  w-full"
      >
        <img src="@/assets/emptyFolder.png" alt="empty folder" width="250" />
        <h1 class="uppercase">{{ $t("views.reports.emptyState") }}</h1>
      </div>

      <heatmap
        :chart-data="chartData"
        :chart-title="t('salesHeatmap')"
        :xAxis="xAxis"
        :key="xAxis"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import Location from "@/models/company/Location";
import Heatmap from "@/components/charts/Heatmap.vue";
import dayjs from "dayjs";
import { useI18n } from "vue-i18n";
import HorizontalButton from "@/components/HorizontalButton.vue";

type ChartData = ApexAxisChartSeries;
type XAxis = Array<string>;

export default defineComponent({
  name: "sales-heatmap",
  components: {
    LoadingSpinner,
    AlertBadge,
    Heatmap,
    HorizontalButton
  },
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          salesHeatmap: "Sales Heatmap",
          chartModes: {
            daily: "Daily",
            weekly: "Weekly",
            monthly: "Monthly"
          },
          generateReport: "Generate Report"
        },
        ar: {
          salesHeatmap: "Sales Heatmap",
          chartModes: {
            daily: "يومي",
            weekly: "أسبوعي",
            monthly: "شهري"
          },
          generateReport: "إنشاء تقرير"
        }
      }
    });

    return { t };
  },
  data() {
    const chartModes = [
      { label: "Monthly", value: "monthly" },
      { label: "Weekly", value: "weekly" },
      { label: "Daily", value: "daily" }
    ];
    const selectedChartMode = "daily";
    const chartData: ChartData | undefined = [] as ChartData;
    const xAxis = [] as XAxis;

    return {
      error: {
        title: "",
        body: ""
      },
      loading: false,
      locations: new Array<Location>(),
      location: "",
      period: [
        dayjs()
          .startOf("month")
          .format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD")
      ], // the default period is from the first day of the month to the current day
      chartModes,
      selectedChartMode,
      chartData,
      xAxis
    };
  },
  async beforeMount() {
    const locationRes = await this.$http.get<Location[]>(Location.ENDPOINT);
    this.locations = locationRes.map(cat => Location.from(cat));
  },

  methods: {
    formatResponseForChart(
      response: Array<{ date: string; sales: Array<number> }>
    ) {
      this.xAxis = [
        "1 am",
        "2 am",
        "3 am",
        "4 am",
        "5 am",
        "6 am",
        "7 am",
        "8 am",
        "9 am",
        "10 am",
        "11 am",
        "12 am",
        "1 pm",
        "2 pm",
        "3 pm",
        "4 pm",
        "5 pm",
        "6 pm",
        "7 pm",
        "8 pm",
        "9 pm",
        "10 pm",
        "11 pm",
        "12 pm"
      ];
      this.chartData = response.map(row => {
        return {
          name: row?.date,
          data: row?.sales
        };
      });
    },
    async updateRowData() {
      try {
        const heatmapRes = await this.$http.get<{
          data: Array<{ date: string; sales: Array<number> }>;
          mode: string;
        }>(
          `/reports/sales/heatmap?from_date=${new Date(
            this.period[0]
          ).toISOString()}&to_date=${new Date(
            this.period[1]
          ).toISOString()}&mode=${this.selectedChartMode}`
        );

        this.formatResponseForChart(heatmapRes.data);
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        });
      }
      this.loading = false;
    }
  }
});
</script>

<style>
.el-date-editor .el-range-separator {
  width: auto !important;
}
</style>
