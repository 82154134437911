<template>
  <div v-if="loading" class="flex items-center justify-center h-full">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = '';
        error.body = '';
      }
    "
  />

  <div class="m-3" style="height:90vh">
    <div class="flex flex-col items-center content-center gap-2 md:flex-row">
      <el-select v-model="selectedMode" :placeholder="t('modePlaceholder')">
        <el-option
          v-for="mode in reportModes"
          :key="mode.label"
          :label="mode.label"
          :value="mode.value"
        />
      </el-select>

      <el-select
        v-model="locationsSelected"
        multiple
        :placeholder="t('storesPlaceholder')"
      >
        <el-option
          v-for="location in locations"
          :key="$ctx.locale === 'en' ? location.enName : location.arName"
          :label="$ctx.locale === 'en' ? location.enName : location.arName"
          :value="location.id"
        />
      </el-select>

      <el-date-picker
        v-if="selectedMode === 'month2month'"
        v-model="period"
        type="monthrange"
        :start-placeholder="t('startDate')"
        :end-placeholder="t('endDate')"
      />
      <el-date-picker
        v-if="selectedMode === 'day2day'"
        v-model="period"
        type="daterange"
        :start-placeholder="t('startDate')"
        :end-placeholder="t('endDate')"
      />
      <div v-if="selectedMode === 'year2year'">
        <el-date-picker
          v-model="period[0]"
          type="year"
          placeholder="Pick a year"
        />
        <span class="demonstration mx-2">-</span>
        <el-date-picker
          v-model="period[1]"
          type="year"
          placeholder="Pick a year"
        />
      </div>
      <div class="w-1/2 md:w-3/12">
        <horizontal-button
          :title="t('generateButton')"
          @click.prevent="updateRowData()"
        />
      </div>
    </div>

    <div
      v-if="salesComparison.comparisonByNumberOfSales === undefined"
      class="flex flex-col justify-center items-center h-5/6 w-full"
    >
      <img src="@/assets/emptyFolder.png" alt="empty folder" width="250" />
      <h1 class="uppercase">{{ $t("views.reports.emptyState") }}</h1>
    </div>

    <div
      v-if="salesComparison.chartXAxis().length !== 0"
      class="flex flex-wrap justify-between"
    >
      <div class="w-full text-3xl mt-8">
        {{ t("sales") }}
      </div>
      <div class="chart-card-width">
        <column-chart
          :xAxis="salesComparison.chartXAxis()"
          :chartData="salesComparison.byValueChartData().sales"
          :chartTitle="t('byValue.sales')"
        />
      </div>
      <div class="chart-card-width">
        <column-chart
          :xAxis="salesComparison.chartXAxis()"
          :chartData="salesComparison.byNumberChartData().sales"
          :chartTitle="t('byNumber.sales')"
        />
      </div>
      <div class="w-full text-3xl mt-12">
        {{ t("returns") }}
      </div>

      <div class="chart-card-width">
        <column-chart
          :xAxis="salesComparison.chartXAxis()"
          :chartData="salesComparison.byValueChartData().returns"
          :chartTitle="t('byValue.returns')"
        />
      </div>
      <div class="chart-card-width">
        <column-chart
          :xAxis="salesComparison.chartXAxis()"
          :chartData="salesComparison.byNumberChartData().returns"
          :chartTitle="t('byNumber.returns')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import Location from "@/models/company/Location";
import ColumnChart from "@/components/charts/ColumnChart.vue";
import { useI18n } from "vue-i18n";
import dayjs from "dayjs";
import HorizontalButton from "@/components/HorizontalButton.vue";

interface ComparisonByNumberOfSalesData {
  dateLabel: string;
  totalSales: number;
  totalReturns: number;
}

interface ComparisonBySalesValueData {
  dateLabel: string;
  currency: string;
  precision: number;
  totalSalesAmount: number;
  totalReturnAmount: number;
  netSalesAmount: number;
}

class ComparisonByNumberOfSales {
  storeName?: string;
  storeID?: string;
  data?: ComparisonByNumberOfSalesData[];
}

class ComparisonBySalesValue {
  storeName?: string;
  storeID?: string;

  data?: ComparisonBySalesValueData[];
}

class SalesComparison {
  static from(json: Record<string, unknown> | SalesComparison) {
    return Object.assign(new SalesComparison(), json);
  }

  static ENDPOINT = "reports/sales/sales-comparison";
  mode?: "year2year" | "month2month" | "day2day";
  comparisonByNumberOfSales?: ComparisonByNumberOfSales[];
  comparisonBySalesValue?: ComparisonBySalesValue[];

  chartXAxis() {
    const months = Array<string>();
    if (this.comparisonByNumberOfSales) {
      this.comparisonByNumberOfSales[0]?.data?.forEach(item => {
        months.push(item.dateLabel);
      });
    }
    return months;
  }

  byValueChartData() {
    const salesByValueChartData = new Array<ApexAxisChartSeries>();
    const returnsByValueChartData = new Array<ApexAxisChartSeries>();
    if (this.comparisonBySalesValue !== undefined) {
      this.comparisonBySalesValue.map(location => {
        const salesStoreData = new Array<number>();
        const returnsStoreData = new Array<number>();
        location.data?.map(item => {
          salesStoreData.push(item.totalSalesAmount);
          returnsStoreData.push(item.totalReturnAmount);
        });
        salesByValueChartData.push(({
          name: location.storeName,
          data: salesStoreData
        } as unknown) as ApexAxisChartSeries);
        returnsByValueChartData.push(({
          name: location.storeName,
          data: returnsStoreData
        } as unknown) as ApexAxisChartSeries);
      });
    }
    return { sales: salesByValueChartData, returns: returnsByValueChartData };
  }
  byNumberChartData() {
    const numberOfSalesChartData = new Array<ApexAxisChartSeries>();
    const numberOfReturnsChartData = new Array<ApexAxisChartSeries>();
    if (this.comparisonByNumberOfSales !== undefined) {
      this.comparisonByNumberOfSales.map(location => {
        const salesStoreData = new Array<number>();
        const returnsStoreData = new Array<number>();
        location.data?.map(item => {
          salesStoreData.push(item.totalSales);
          returnsStoreData.push(item.totalReturns);
        });
        numberOfSalesChartData.push(({
          name: location.storeName,
          data: salesStoreData
        } as unknown) as ApexAxisChartSeries);
        numberOfReturnsChartData.push(({
          name: location.storeName,
          data: returnsStoreData
        } as unknown) as ApexAxisChartSeries);
      });
    }
    return { sales: numberOfSalesChartData, returns: numberOfReturnsChartData };
  }
}
export default defineComponent({
  name: "sales-comparison",
  components: {
    LoadingSpinner,
    AlertBadge,
    ColumnChart,
    HorizontalButton
  },
  data() {
    const salesComparison = new SalesComparison();
    const selectedMode = "";
    const reportModes = [
      {
        label: "Year to year",
        value: "year2year"
      },
      {
        label: "Month to Month",
        value: "month2month"
      },
      {
        label: "Day to Day",
        value: "day2day"
      }
    ];
    const locationsSelected = Array<string>();
    const categories: string[] = [];
    const comparisonMode = "";
    const messages = {
      en: {
        sales: "Sales",
        returns: "Returns",
        byValue: {
          sales: "Sales By Value",
          returns: "Returns By Value"
        },
        byNumber: {
          sales: "Number Of Sales",
          returns: "Number Of Returns"
        },
        startDate: "From",
        endDate: "To",
        modePlaceholder: "Select Comparison Mode",
        storesPlaceholder: "Select Stores",
        generateButton: "Generate Report"
      },
      ar: {
        sales: "Sales",
        returns: "Returns",
        byValue: {
          sales: "Sales By Value",
          returns: "Returns By Value"
        },
        byNumber: {
          sales: "Number Of Sales",
          returns: "Number Of Returns"
        },
        startDate: "From",
        endDate: "To",
        modePlaceholder: "Select Comparison Mode",
        storesPlaceholder: "Select Stores",
        generateButton: "Generate Report"
      }
    };

    const { t } = useI18n({
      messages
    });

    return {
      categories,
      error: {
        title: "",
        body: ""
      },
      loading: false,
      locations: new Array<Location>(),
      location: "",
      period: [dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
      t,
      comparisonMode,
      locationsSelected,
      reportModes,
      selectedMode,
      salesComparison
    };
  },
  async beforeMount() {
    const locationsResp = await this.$http.get<Location[]>(Location.ENDPOINT);
    this.locations = locationsResp.map(location => Location.from(location));
  },

  methods: {
    async updateRowData() {
      try {
        this.loading = true;
        const salesComparisonResp = await this.$http.get<SalesComparison>(
          `${SalesComparison.ENDPOINT}?location_ids=${this.locations[0].id},${
            this.locations[1].id
          }&from_date=${new Date(
            this.period[0]
          ).toISOString()}&to_date=${new Date(
            this.period[1]
          ).toISOString()}&mode=${this.selectedMode}`
        );

        this.salesComparison = SalesComparison.from(salesComparisonResp);
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        });
      }
      this.loading = false;
    }
  }
});
</script>

<style>
.el-date-editor .el-range-separator {
  width: auto !important;
}
.chart-card-width {
  width: 47%;
}
</style>
