
import { defineComponent } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import Location from "@/models/company/Location";
import BarChart from "@/components/charts/BarChart.vue";
import { useI18n } from "vue-i18n";
import { Dinero as DineroType } from "dinero.js";
import { getPath } from "./utils";
import dayjs from "dayjs";

class SaleByItem {
  static from(json: Record<string, unknown> | SaleByItem) {
    return Object.assign(new SaleByItem(), json);
  }

  static ENDPOINT = "reports/sales/sales-by-item";

  itemName?: string;
  itemID?: string;
  totalSoldAmount?: number;
  totalSoldCurrency?: string;
  totalSoldPrecision?: number;
  totalSoldDinero?: DineroType;
}
export default defineComponent({
  name: "sales-by-item",
  components: {
    LoadingSpinner,
    AlertBadge,
    BarChart
  },
  data() {
    const rowData: SaleByItem[] = [];
    const items: string[] = [];
    const totals: number[] = [];
    const messages = {
      en: {
        startDate: "Start Date",
        endDate: "End Date",
        salesByItem: "Sales By Item"
      },
      ar: {
        startDate: "Start Date",
        endDate: "End Date",
        salesByItem: "Sales By Item"
      }
    };

    const { t } = useI18n({
      messages
    });
    return {
      rowData,
      error: {
        title: "",
        body: ""
      },
      loading: false,
      locations: new Array<Location>(),
      location: "",
      items,
      period: [
        dayjs()
          .startOf("month")
          .format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD")
      ], // the default period is from the first day of the month to the current day
      t,
      totals
    };
  },
  async beforeMount() {
    const locationsResp = await this.$http.get<Location[]>(Location.ENDPOINT);
    this.locations = locationsResp.map(cat => Location.from(cat));
    this.updateRowData();
  },
  watch: {
    period: {
      handler: function() {
        this.updateRowData();
      }
    },
    location: {
      handler: function() {
        this.updateRowData();
      }
    }
  },
  methods: {
    async updateRowData() {
      try {
        const salesByItemResp = await this.$http.get<SaleByItem[]>(
          getPath(SaleByItem.ENDPOINT, this.location, this.period)
        );
        this.rowData = salesByItemResp.map(saleByItem =>
          SaleByItem.from(saleByItem)
        );
        this.rowData.forEach(item => {
          //here separating the names and values so we can send it to the chart
          this.items.push(item.itemName ? item.itemName : "");
          this.totals.push(item.totalSoldAmount ? item.totalSoldAmount : 0);
        });
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        });
      }
      this.loading = false;
    }
  }
});
