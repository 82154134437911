<template>
  <div>
    <div v-if="loading" class="flex items-center justify-center h-full">
      <loading-spinner diameter="64px" />
    </div>

    <alert-badge
      v-else-if="error.title"
      isDanger
      :title="error.title"
      :body="error.body"
      @dismissed="
        () => {
          error.title = '';
          error.body = '';
        }
      "
    />

    <div class="m-3" style="height:90vh">
      <div class="flex flex-col items-center content-center gap-2 md:flex-row">
        <el-select class="input-label" v-model="location">
          <el-option
            v-for="(loc, i) in locations"
            :key="i"
            :label="$ctx.getDir() === 'rtl' ? loc.arName : loc.enName"
            :value="loc.id"
          >
          </el-option>
        </el-select>

        <el-date-picker
          v-model="selectedDate"
          type="date"
          :placeholder="t('selectDate')"
        />
        <div class="w-1/4 ">
          <horizontal-button
            :title="t('generateReport')"
            @click="updateRowData()"
          />
        </div>
      </div>

      <div
        v-if="registerClosing.locationID == null"
        class="flex flex-col justify-center items-center h-5/6  w-full"
      >
        <img src="@/assets/emptyFolder.png" alt="empty folder" width="250" />
        <h1 class="uppercase">{{ $t("views.reports.emptyState") }}</h1>
      </div>
      <receipt
        v-else
        :operationTitle="t('reportTitle')"
        :externalID="
          registerClosing.externalID ? registerClosing.externalID : ''
        "
        :date="new Date()"
        :locationName="
          registerClosing.location ? registerClosing.location.enName : ''
        "
      >
        <template v-slot:receiptContent>
          <el-row class="w-full mt-6">
            <el-col class="flex justify-center font-bold">{{
              t("openingInfo.title")
            }}</el-col>
            <el-col :span="24" class="p-2">
              <el-row>
                <el-col :span="12">{{ t("openingInfo.dateAndTime") }}</el-col>
                <el-col :span="12" class="flex justify-end">{{
                  registerClosing.formatTimestamp(
                    registerClosing.openingDate
                  )
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="12">{{ t("openingInfo.openedBy") }}</el-col>
                <el-col :span="12" class="flex justify-end">{{
                  registerClosing.openedBy?.username
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="12">{{
                  t("openingInfo.lastShiftFloatCash")
                }}</el-col>
                <el-col :span="12" class="flex justify-end">{{
                  registerClosing.getDineroFormatFromAmount(
                    registerClosing.lastShiftFloatCash
                  )
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="12">{{ t("openingInfo.floatCash") }}</el-col>
                <el-col :span="12" class="flex justify-end">{{
                  registerClosing.getDineroFormatFromAmount(
                    registerClosing.openingFloatCash
                  )
                }}</el-col>
              </el-row>
              <hr />
            </el-col>
          </el-row>

          <el-row class="w-full">
            <el-col class="flex justify-center font-bold">{{
              t("transInfo.title")
            }}</el-col>
            <el-col :span="24" class="p-2">
              <el-row>
                <el-col :span="12">{{ t("transInfo.cashIn") }}</el-col>
                <el-col :span="12" class="flex justify-end">{{
                  registerClosing.getDineroFormatFromAmount(
                    registerClosing.cashIn
                  )
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="12">{{ t("transInfo.refunds") }}</el-col>
                <el-col :span="12" class="flex justify-end">{{
                  registerClosing.getDineroFormatFromAmount(
                    registerClosing.refunds
                  )
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="12">{{ t("transInfo.addedCash") }}</el-col>
                <el-col :span="12" class="flex justify-end">{{
                  registerClosing.getDineroFormatFromAmount(
                    registerClosing.addedCash
                  )
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="12">{{ t("transInfo.droppedCash") }}</el-col>
                <el-col :span="12" class="flex justify-end">{{
                  registerClosing.getDineroFormatFromAmount(
                    registerClosing.droppedCash
                  )
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="12" class="font-bold">{{
                  t("transInfo.totalCash")
                }}</el-col>
                <el-col :span="12" class="flex justify-end font-bold">{{
                  registerClosing.getDineroFormatFromAmount(
                    registerClosing.totalCash
                  )
                }}</el-col>
              </el-row>
              <hr />
            </el-col>
          </el-row>

          <el-row class="w-full">
            <el-col class="flex justify-center font-bold">{{
              t("closingInfo.title")
            }}</el-col>

            <el-col :span="24" class="p-2">
              <el-row>
                <el-col :span="12">{{ t("closingInfo.dateAndTime") }}</el-col>
                <el-col :span="12" class="flex justify-end">{{
                  registerClosing.formatTimestamp(
                    registerClosing.closingDate
                  )
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="12">{{ t("closingInfo.closedBy") }}</el-col>
                <el-col :span="12" class="flex justify-end">{{
                  registerClosing.closedBy?.firstName
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="12">{{ t("closingInfo.floatCash") }}</el-col>
                <el-col :span="12" class="flex justify-end">{{
                  registerClosing.getDineroFormatFromAmount(
                    registerClosing.closingFloatCash
                  )
                }}</el-col>
              </el-row>
              <hr />
            </el-col>
          </el-row>

          <el-row class="w-full">
            <el-col class="flex justify-center font-bold">{{
              t("depositInfo.title")
            }}</el-col>

            <el-col :span="24" class="p-2">
              <el-row>
                <el-col :span="12" class="font-bold flex items-center">{{
                  t("depositInfo.depositedCash")
                }}</el-col>
                <el-col :span="12" class="flex justify-end font-bold">{{
                  registerClosing.getDineroFormatFromAmount(
                    registerClosing.depositedCash
                  )
                }}</el-col>
              </el-row>
              <hr />
            </el-col>
          </el-row>

          <el-row class="w-full">
            <el-col class="flex justify-center font-bold">{{
              t("variances.title")
            }}</el-col>

            <el-col :span="24" class="p-2">
              <el-row>
                <el-col :span="12">{{ t("variances.floatCash") }}</el-col>
                <el-col :span="12" class="flex justify-end">{{
                  registerClosing.getDineroFormatFromAmount(
                    registerClosing.floatCashVariance
                  )
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="12">{{ t("variances.depositedCash") }}</el-col>
                <el-col :span="12" class="flex justify-end">{{
                  registerClosing.getDineroFormatFromAmount(
                    registerClosing.depositedCashVariance
                  )
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="12" class="font-bold">{{
                  t("variances.totalCash")
                }}</el-col>
                <el-col :span="12" class="flex justify-end font-bold">{{
                  registerClosing.getDineroFormatFromAmount(
                    registerClosing.totalCashVariance
                  )
                }}</el-col>
              </el-row>
            </el-col>
          </el-row>
        </template>
      </receipt>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import Location from "@/models/company/Location";
import { ColDef, GridOptions } from "ag-grid-community";
import { useI18n } from "vue-i18n";
import dayjs from "dayjs";
import HorizontalButton from "@/components/HorizontalButton.vue";
import Dinero from "dinero.js";
import { formatDinero } from "@/utils/money";
import Receipt from "@/components/Receipt.vue";
import User from "@/models/user/User";

class RegisterClosing {
  static from(json: Record<string, unknown> | RegisterClosing) {
    return Object.assign(new RegisterClosing(), json);
  }
  static ENDPOINT = "reports/sales/register-closing";

  id?: string;
  externalID?: string;
  location?: Location;
  locationID?: string;

  openingDate?: string;
  openedByID?: string;
  openedBy?: User;

  currency?: "IQD" | "USD";
  precision?: number;
  
  lastShiftFloatCash?: number;
  openingFloatCash?: number;

  cashIn?: number;
  refunds?: number;
  addedCash?: number;
  droppedCash?: number;
  totalCash?: number;

  closingDate?: string;
  closedByID?: string;
  closedBy?: User;
  closingFloatCash?: number;

  depositedCash?: number;
  floatCashVariance?: number;
  depositedCashVariance?: number;
  totalCashVariance?: number;

  getDineroFormatFromAmount(amount: number | undefined) {
    return formatDinero(
      Dinero({
        amount: amount,
        precision: this.precision,
        currency: this.currency
      })
    );
  }

  formatTimestamp(timestamp: string | undefined) {
    return dayjs(timestamp).format("YYYY/MM/DD - hh:MM A");
  }
}
export default defineComponent({
  components: {
    LoadingSpinner,
    AlertBadge,
    HorizontalButton,
    Receipt
  },

  data() {
    const columnDefs: ColDef[] = [];
    const registerClosing: RegisterClosing = new RegisterClosing();
    const gridOptions: GridOptions = {};
    const defaultColDef: ColDef = {};
    const messages = {
      en: {
        selectDate: "Select Date",
        selectStore: "Select Store",
        print: "Print",
        store: "Store",
        generateReport: "Generate Report",
        reportTitle: "Register Closing Report",
        openingInfo: {
          title: "Opening Info",
          dateAndTime: "Opening Date & Time",
          openedBy: "Opened By",
          floatCash: "Opening Float Cash",
          lastShiftFloatCash: "Last Shift Float Cash"
        },
        transInfo: {
          title: "Trans Info",
          cashIn: "Cash In +",
          refunds: "Refunds -",
          addedCash: "Added Cash +",
          droppedCash: "Dropped Cash -",
          totalCash: "Total Cash"
        },
        closingInfo: {
          title: "Closing Info",
          dateAndTime: "Closing Date & Time",
          closedBy: "Closed By",
          floatCash: "Closing Float Cash"
        },
        depositInfo: {
          title: "Deposit Info",
          depositedCash: "Deposited Cash"
        },
        variances: {
          title: "Variances",
          floatCash: "Float Cash Variance",
          depositedCash: "Deposited Cash Variance",
          totalCash: "Total Cash Variance"
        }
      },
      ar: {
        selectDate: "Select Date",
        selectStore: "Select Store",
        store: "Store",
        generateReport: "Generate Report",
        reportTitle: "Register Closing Report",
        print: "Print",
        openingInfo: {
          title: "Opening Info",
          dateAndTime: "Opening Date & Time",
          openedBy: "Opened By",
          floatCash: "Opening Float Cash",
          lastShiftFloatCash: "Last Shift Float Cash"
        },
        transInfo: {
          title: "Trans Info",
          cashIn: "Cash In +",
          refunds: "Refunds -",
          addedCash: "Added Cash +",
          droppedCash: "Dropped Cash -",
          totalCash: "Total Cash"
        },
        closingInfo: {
          title: "Closing Info",
          dateAndTime: "Closing Date & Time",
          closedBy: "Closed By",
          floatCash: "Closing Float Cash"
        },
        depositInfo: {
          title: "Deposit Info",
          depositedCash: "Deposited Cash"
        },
        variances: {
          title: "Variances",
          floatCash: "Float Cash Variance",
          depositedCash: "Deposited Cash Variance",
          totalCash: "Total Cash Variance"
        }
      }
    };

    const { t } = useI18n({
      messages
    });
    return {
      columnDefs,
      registerClosing,
      gridOptions,
      defaultColDef,
      error: {
        title: "",
        body: ""
      },
      loading: false,
      locations: new Array<Location>(),
      location: "",
      selectedDate: dayjs().format("YYYY-MM-DD"),
      t
    };
  },

  async beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 100,
      cellStyle: { textAlign: "center" },
      flex: 1
    };
    this.columnDefs = [
      {
        headerName: this.t("name"),
        field: "itemName",
        checkboxSelection: true,
        autoHeight: true,
        cellStyle: { textAlign: "start" },
        headerCheckboxSelection: true,
        minWidth: 500
      },
      {
        headerName: this.t("quantity"),
        field: "quantity",
        type: "numericColumn",
        filter: "agNumberColumnFilter",
        cellStyle: { textAlign: "center" }
      }
    ];
    const locationRes = await this.$http.get<Location[]>(Location.ENDPOINT);
    this.locations = locationRes.map(cat => Location.from(cat));
  },

  methods: {
    print() {
      window.print();
    },
    async updateRowData() {
      if (!this.location) {
        return;
      }

      this.gridOptions.api?.showLoadingOverlay();
      try {
        const data: any = await this.$http.get<RegisterClosing>(`${RegisterClosing.ENDPOINT}?location_id=${this.location}&closing_date=${dayjs(this.selectedDate).format("YYYY-MM-DD")}`);
        this.registerClosing = RegisterClosing.from(data);
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        });
      }
      this.gridOptions.api?.hideOverlay();
    },

    async onGridReady() {
      await this.updateRowData();
    }
  }
});
</script>

<style scoped>
.el-date-editor .el-range-separator {
  width: auto !important;
}

.el-divider {
  background-color: black;
  margin-block: 1rem;
}
</style>
