
import { defineComponent } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import Location from "@/models/company/Location";
import BarChart from "@/components/charts/BarChart.vue";
import { Dinero as DineroType } from "dinero.js";
import { getPath } from "./utils";
import dayjs from "dayjs";

class SaleByCategory {
  static from(json: Record<string, unknown> | SaleByCategory) {
    return Object.assign(new SaleByCategory(), json);
  }

  static ENDPOINT = "reports/sales/sales-by-category";

  categoryName?: string;
  categoryID?: string;
  totalSoldAmount?: number;
  totalSoldCurrency?: string;
  totalSoldPrecision?: number;
  totalSoldDinero?: DineroType;
}

export default defineComponent({
  name: "sales-by-category",
  components: {
    LoadingSpinner,
    AlertBadge,
    BarChart
  },
  data() {
    const rowData: SaleByCategory[] = [];
    const categories: string[] = [];
    const totals: number[] = [];
    return {
      rowData,
      error: {
        title: "",
        body: ""
      },
      loading: false,
      locations: new Array<Location>(),
      location: "",
      categories,
      period: [
        dayjs()
          .startOf("month")
          .format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD")
      ], // the default period is from the first day of the month to the current day
      totals
    };
  },
  async beforeMount() {
    const catResp = await this.$http.get<Location[]>(Location.ENDPOINT);
    this.locations = catResp.map(cat => Location.from(cat));
    this.updateRowData();
  },
  watch: {
    period: {
      handler: function() {
        this.updateRowData();
      }
    },
    location: {
      handler: function() {
        this.updateRowData();
      }
    }
  },
  methods: {
    async updateRowData() {
      try {
        const salesByCategoryResp = await this.$http.get<SaleByCategory[]>(
          getPath(SaleByCategory.ENDPOINT, this.location, this.period)
        );
        this.rowData = salesByCategoryResp.map(saleByCategory =>
          SaleByCategory.from(saleByCategory)
        );

        this.rowData.forEach(category => {
          this.categories.push(
            category.categoryName ? category.categoryName : ""
          );
          this.totals.push(
            category.totalSoldAmount ? category.totalSoldAmount : 0
          );
        });
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        });
      }
      this.loading = false;
    }
  }
});
