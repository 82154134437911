
import { defineComponent } from "vue";

// components
import { AgGridVue } from "ag-grid-vue3";
import { ColDef, GridOptions } from "ag-grid-community";
import HorizontalButton from "@/components/HorizontalButton.vue";
import Location from "@/models/company/Location";

import { useI18n } from "vue-i18n";
import { getPath } from "../sales/utils";

class InventoryQuantity {

  static from(json: Record<string, unknown> | InventoryQuantity) {
    return Object.assign(new InventoryQuantity(), json);
  }

  static ENDPOINT = "reports/inventory-quantity";

  low ?: number;
  high ?: number;
  arLocationName ?: string;
  enLocationName  ?: string;
  locationID = "";
  items: ItemLocationQuantity[] = [];
}

interface ItemLocationQuantity {
  itemName: string,
  itemID: string,
  quantity: number
}

export default defineComponent({
  name: "quantity-report",

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          highInventory: "High Inventory",
          lowInventory: "Low Inventory",
          negativeInventory: "Negative Inventory",
          itemName: "Item Name",
          quantity: "Quantity",
          location: "Location",
          generateReport: "Generate Report",
          selectLocation: "Select Location"
        },
        ar: {
          highInventory: "High Inventory",
          lowInventory: "Low Inventory",
          negativeInventory: "Negative Inventory",
          itemName: "اسم المادة",
          quantity: "الكمية",
          location: "الموقع",
          generateReport: "إنشاء تقرير",
          selectLocation: "اختر الموقع"
        }
      }
    });

    return { t };
  },

  data() {
    const columnDefs: ColDef[] = [];
    const rowData: ItemLocationQuantity[] = [];
    const gridOptions: GridOptions = {};
    const defaultColDef: ColDef = {};
    let highestAmount: number | undefined;
    let lowestAmount: number | undefined;
    const shortcut = "";
    return {
      shortcut,
      highestAmount,
      lowestAmount,
      locations: new Array<Location>(),
      location: "",
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef
    };
  },

  components: {
    AgGridVue,
    HorizontalButton
  },

  watch: {
    shortcut() {
      if (this.shortcut === "low") {
        this.lowestAmount = 0;
        this.highestAmount = 10;
      }
      if (this.shortcut === "high") {
        this.lowestAmount = 50;
        this.highestAmount = undefined;
      }
      if (this.shortcut === "negative") {
        this.lowestAmount = undefined;
        this.highestAmount = -1;
      }
    }
  },

  async beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 150
    };

    this.columnDefs = [
      {
        headerName: this.t("itemName"),
        field: "itemName",
        checkboxSelection: true,
        headerCheckboxSelection: true
      },
      {
        headerName: this.t("quantity"),
        field: "quantity",
        cellStyle: {textAlign: 'center'}
      }
    ];

    const locationsResp = await this.$http.get<Location[]>(Location.ENDPOINT);
    this.locations = locationsResp.map(cat => Location.from(cat));
  },

  mounted() {
    this.gridOptions.api?.sizeColumnsToFit();
  },

  methods: {
    async updateRowData() {
      this.gridOptions.api?.showLoadingOverlay();
      try {
        const res = await this.$http.get<InventoryQuantity>(
          `${getPath(InventoryQuantity.ENDPOINT, this.location)}&low=${this.lowestAmount ? this.lowestAmount : ""}&high=${this.highestAmount ? this.highestAmount : ""}`
        );

        const report = InventoryQuantity.from(res);

        this.rowData = report.items; 
      } catch (error: any) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        });
      }

      this.gridOptions.api?.hideOverlay();
    }
  }
});
