<template>
  <div>
    <div v-if="loading" class="flex items-center justify-center h-full">
      <loading-spinner diameter="64px" />
    </div>

    <alert-badge
      v-else-if="error.title"
      isDanger
      :title="error.title"
      :body="error.body"
      @dismissed="
        () => {
          error.title = '';
          error.body = '';
        }
      "
    />

    <div class="m-3" style="height:90vh">
      <div class="flex flex-col items-center content-center gap-2 md:flex-row">
        <el-select class="input-label" v-model="location">
          <el-option
            v-for="(loc, i) in locations"
            :key="i"
            :label="$ctx.locale === 'en' ? loc.enName : loc.arName"
            :value="loc.id"
          >
          </el-option>
        </el-select>

        <el-date-picker
          v-model="selectedDate"
          type="date"
          :placeholder="t('selectDate')"
        />
      </div>

      <receipt
        externalID="123456"
        :operationTitle="t('reportTitle')"
        :date="new Date(parseInt(endOfDay.reportDate))"
        :locationName="location"
      >
        <template v-slot:receiptContent v-if="endOfDay">
          <el-row class="w-full mt-6">
            <el-col :span="24" class="p-2">
              <el-row>
                <el-col :span="12">{{ t("company.totalStores") }}</el-col>
                <el-col :span="12" class="flex justify-end">{{
                  endOfDay.storeData?.length
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="12">{{ t("company.totalSales") }}</el-col>
                <el-col :span="12" class="flex justify-end">{{
                  endOfDay.getDineroFormatFromAmount(endOfDay.totalSalesAmount)
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="12">{{
                  t("company.totalWorkingEmployees")
                }}</el-col>
                <el-col :span="12" class="flex justify-end">{{
                  endOfDay.getDineroFormatFromAmount(
                    endOfDay.totalWorkingEmployees
                  )
                }}</el-col>
              </el-row>
              <hr />
            </el-col>
          </el-row>

          <el-row
            class="w-full"
            v-for="(store, index) in endOfDay.storeData"
            :key="index"
          >
            <el-col class="flex justify-center font-bold">{{
              store.locationName
            }}</el-col>
            <el-col :span="24" class="p-2">
              <el-row>
                <el-col :span="12">{{ t("store.totalSales") }}</el-col>
                <el-col :span="12" class="flex justify-end">{{
                  endOfDay.getDineroFormatFromAmount(store.totalSalesAmount)
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="12">{{ t("store.totalItemsSold") }}</el-col>
                <el-col :span="12" class="flex justify-end">{{
                  store.totalItemsSold
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="12">{{
                  t("store.totalDepositedAmount")
                }}</el-col>
                <el-col :span="12" class="flex justify-end">{{
                  endOfDay.getDineroFormatFromAmount(store.totalDepositedAmount)
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="12">{{ t("store.registers") }}</el-col>
                <el-col :span="12" class="flex justify-end">{{
                  store.registers.length
                }}</el-col>
                <el-row
                  class="w-full"
                  v-for="(register, index) in store.registers"
                  :key="index"
                >
                  <el-col :span="1"></el-col>
                  <el-col :span="23">
                    <el-row>
                      <el-col :span="12">
                        <el-row>
                          <el-col :span="12">{{
                            register.registerName
                          }}</el-col>
                          <el-col
                            :span="12"
                            class="flex justify-center"
                            :class="
                              register.registerStatus === 'closed'
                                ? 'text-red-500'
                                : 'text-green-500'
                            "
                            >{{ register.registerStatus }}</el-col
                          >
                        </el-row>
                      </el-col>
                      <el-col :span="12" class="flex justify-end">{{
                        endOfDay.getDineroFormatFromAmount(register.totalAmount)
                      }}</el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-row>
              <el-row>
                <el-col :span="12">{{ t("store.employees") }}</el-col>
                <el-col :span="12" class="flex justify-end">{{
                  store.employees.length
                }}</el-col>
                <el-row
                  class="w-full"
                  v-for="(employee, index) in store.employees"
                  :key="index"
                >
                  <el-col :span="1"></el-col>
                  <el-col :span="23">
                    <el-row>
                      <el-col :span="12">
                        {{ employee.employeeName }}
                      </el-col>
                      <el-col :span="12" class="flex justify-end">
                        <el-row>
                          <el-col
                            class="flex justify-end "
                            :span="24"
                            v-for="(shift, index) in employee.shifts"
                            :key="index"
                          >
                            {{ endOfDay.formatTimestamp(shift.from) }} -
                            {{ endOfDay.formatTimestamp(shift.to) }}
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-row>
              <hr />
            </el-col>
          </el-row>
        </template>
      </receipt>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import Location from "@/models/company/Location";
import { ColDef, GridOptions } from "ag-grid-community";
import { useI18n } from "vue-i18n";
import dayjs from "dayjs";
import Dinero from "dinero.js";
import { formatDinero } from "@/utils/money";
import Receipt from "@/components/Receipt.vue";

type registerInfo = {
  registerID: string;
  registerName: string;
  totalAmount: number;
  registerStatus: string;
};

type employeeInfo = {
  employeeID: string;
  employeeName: string;
  shifts: {
    from: string;
    to: string;
  }[];
};

type storeData = {
  locationID: string;
  locationName: string;
  totalSalesAmount: number;
  totalDepositedAmount: number;
  totalItemsSold: number;
  registers: registerInfo[];
  employees: employeeInfo[];
};

class EndOfDay {
  static from(json: Record<string, unknown> | EndOfDay) {
    return Object.assign(new EndOfDay(), json);
  }

  static ENDPOINT = "/reports/sales/end-of-day";

  reportDate = "";
  numberOfStores?: number;
  totalSalesAmount?: number;
  currency?: "USD" | "IQD";
  precision?: number;
  totalWorkingEmployees?: number;
  storeData?: storeData[];

  getDineroFormatFromAmount(amount: number | undefined) {
    return formatDinero(
      Dinero({
        amount: amount,
        precision: this.precision,
        currency: this.currency
      })
    );
  }
  formatTimestamp(timestamp: string | undefined) {
    return dayjs(timestamp).format("hh:MM A");
  }
}

export default defineComponent({
  components: {
    LoadingSpinner,
    AlertBadge,
    Receipt
  },
  data() {
    const columnDefs: ColDef[] = [];
    const endOfDay: EndOfDay = {} as EndOfDay;
    const gridOptions: GridOptions = {};
    const defaultColDef: ColDef = {};
    const messages = {
      en: {
        selectDate: "Select Date",
        selectStore: "Select Store",
        print: "Print",
        reportTitle: "End of Day",
        company: {
          name: "Company Name",
          totalStores: "Total Stores",
          totalWorkingEmployees: "Total Working Employees",
          totalSales: "Total Sales"
        },
        store: {
          name: "Store Name",
          totalSales: "Total Sales",
          totalItemsSold: "Total Items Sold",
          totalDepositedAmount: "Total Deposited Amount",
          registers: "Registers",
          employees: "Employees"
        }
      },
      ar: {
        selectDate: "Select Date",
        selectStore: "Select Store",
        print: "Print",
        reportTitle: "End of Day",
        company: {
          name: "Company Name",
          totalStores: "Total Stores",
          totalWorkingEmployees: "Total Working Employees",
          totalSales: "Total Sales"
        },
        store: {
          name: "Store Name",
          totalSales: "Total Sales",
          totalItemsSold: "Total Items Sold",
          totalDepositedAmount: "Total Deposited Amount",
          registers: "Registers",
          employees: "Employees"
        }
      }
    };
    const { t } = useI18n({
      messages
    });
    return {
      columnDefs,
      endOfDay,
      gridOptions,
      defaultColDef,
      error: {
        title: "",
        body: ""
      },
      loading: false,
      locations: new Array<Location>(),
      location: "",
      selectedDate: dayjs()
        .startOf("month")
        .format("YYYY-MM-DD"),
      t
    };
  },
  async beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 100,
      cellStyle: { textAlign: "center" },
      flex: 1
    };
    this.columnDefs = [
      {
        headerName: this.t("name"),
        field: "itemName",
        checkboxSelection: true,
        autoHeight: true,
        cellStyle: { textAlign: "start" },
        headerCheckboxSelection: true,
        minWidth: 500
      },
      {
        headerName: this.t("quantity"),
        field: "quantity",
        type: "numericColumn",
        filter: "agNumberColumnFilter",
        cellStyle: { textAlign: "center" }
      }
    ];
    const locationRes = await this.$http.get<Location[]>(Location.ENDPOINT);
    this.locations = locationRes.map(cat => Location.from(cat));
    this.updateRowData();
  },
  watch: {
    selectedDate: {
      handler: function() {
        this.updateRowData();
      }
    },
    location: {
      handler: function() {
        this.updateRowData();
      }
    }
  },
  methods: {
    print() {
      window.print();
    },
    async updateRowData() {
      this.gridOptions.api?.showLoadingOverlay();

      try {
        const endOfDayResp = await this.$http.get<EndOfDay[]>(
          `${EndOfDay.ENDPOINT}?closing_date=${new Date(
            this.selectedDate
          ).toISOString()}`
        );
        const mockRes = {
          reportDate: "1653986887876",
          numberOfStores: 2,
          totalSalesAmount: 2850,
          currency: "USD",
          precision: 0,
          totalWorkingEmployees: 3,
          storeData: [
            {
              locationID: "abc",
              locationName: "Al-Mnasour",
              totalSalesAmount: 1340,
              totalDepositedAmount: 1300,
              totalItemsSold: 15,
              registers: [
                {
                  registerID: "...",
                  registerName: "Register #1",
                  totalAmount: 450,
                  registerStatus: "closed"
                },
                {
                  registerID: "...",
                  registerName: "Register #2",
                  totalAmount: 600,
                  registerStatus: "closed"
                },
                {
                  registerID: "...",
                  registerName: "Register #3",
                  totalAmount: 600,
                  registerStatus: "open"
                }
              ],
              employees: [
                {
                  employeeID: "...",
                  employeeName: "Ahmed Mohammed",
                  shifts: [
                    {
                      from: "1653986887876",
                      to: "1653986887876"
                    }
                  ]
                },
                {
                  employeeID: "...",
                  employeeName: "Hussein Talal",
                  shifts: [
                    {
                      from: "1653986887876",
                      to: "1653986887876"
                    }
                  ]
                }
              ]
            },
            {
              locationID: "abc",
              locationName: "Al-Karada",
              totalSalesAmount: 1500,
              totalDepositedAmount: 1450,
              totalItemsSold: 15,
              registers: [
                {
                  registerID: "...",
                  registerName: "Register #1",
                  totalAmount: 750,
                  registerStatus: "closed"
                },
                {
                  registerID: "...",
                  registerName: "Register #2",
                  totalAmount: 250,
                  registerStatus: "closed"
                },
                {
                  registerID: "...",
                  registerName: "Register #3",
                  totalAmount: 500,
                  registerStatus: "closed"
                }
              ],
              employees: [
                {
                  employeeID: "...",
                  employeeName: "Ahmed Mohammed",
                  shifts: [
                    {
                      from: "1653986887876",
                      to: "1653986887876"
                    },
                    {
                      from: "1653986887876",
                      to: "1653986887876"
                    }
                  ]
                }
              ]
            }
          ]
        };
        this.endOfDay = EndOfDay.from(mockRes);
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        });
      }
      1;
      this.gridOptions.api?.hideOverlay();
    },
    async onGridReady() {
      await this.updateRowData();
    }
  }
});
</script>

<style scoped>
.el-date-editor .el-range-separator {
  width: auto !important;
}
.el-divider {
  background-color: black;
  margin-block: 1rem;
}
</style>
