
import { defineComponent } from "vue";

// components
import HorizontalButton from "@/components/HorizontalButton.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import { ElForm } from "element-plus";
import MoneyInput from "@/components/form/MoneyInput.vue";
import { useI18n } from "vue-i18n";
import { Dinero } from "dinero.js";
import { formatMoney } from "@/utils/money";

interface RegisterClosing {
  openingFloatCash: number;
  openingFloatCashCurrency: "IQD" | "USD";
  openingFloatCashPrecision: number;
  openingFloatCashDinero: Dinero;

  closingFloatCash: number;
  closingFloatCashCurrency: "IQD" | "USD";
  closingFloatCashPrecision: number;
  closingFloatCashDinero: Dinero;

  closingDepositedCash: number;
  closingDepositedCashCurrency: "IQD" | "USD";
  closingDepositedCashPrecision: number;
  closingDepositedCashDinero: Dinero;
}

export default defineComponent({
  name: "new-register-closing",

  setup() {
    const messages = {
      en: {},

      ar: {}
    };

    const { t } = useI18n({ messages, useScope: "global" });

    const rules = {
      openingFloatCash: [{ required: true, message: t("validation.required") }],
      closingFloatCash: [{ required: true, message: t("validation.required") }],
      closingDepositedCash: [
        { required: true, message: t("validation.required") }
      ]
    };

    return { t, rules };
  },

  components: {
    HorizontalButton,
    AlertBadge,
    MoneyInput
  },

  data() {
    const registerClosing = {} as RegisterClosing;
    return {
      registerClosing,
      error: { title: "", body: "" },
      clearFormOnSubmit: true,
      enableClearForm: true
    };
  },
  async beforeMount() {
    try {
      console.log("something here");
    } catch (error) {
      this.error.title = error?.title;
      this.error.body = error?.body;
    }
  },
  methods: {
    formatMoney,
    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;

      this.registerClosing = {} as RegisterClosing;
      this.$globalEmitter.emit("money-input-reset");
      form.resetFields();
    },

    async submitAction() {
      try {
        // const url = `${Item.ENDPOINT}/${this.$route.params.id}`;
        // const resp = await this.$http.put<Item>(url, item);
        // this.item = Item.from(resp);
      } catch (error) {
        return error;
      }
    },

    async validateSubmit(formName: string) {
      // attach the price and cost values
      // this.item.listPriceAmount = this.item.listPriceDinero?.getAmount();
      // this.item.listPriceCurrency = this.item.listPriceDinero?.getCurrency();
      // this.item.listPricePrecision = this.item.listPriceDinero?.getPrecision();

      // this.item.salePriceAmount = this.item.salePriceDinero?.getAmount();
      // this.item.salePriceCurrency = this.item.salePriceDinero?.getCurrency();
      // this.item.salePricePrecision = this.item.salePriceDinero?.getPrecision();

      // this.item.costAmount = this.item.costDinero?.getAmount();
      // this.item.costCurrency = this.item.costDinero?.getCurrency();
      // this.item.costPrecision = this.item.costDinero?.getPrecision();

      const form = this.$refs[formName] as typeof ElForm;
      form?.validate(async (valid: boolean) => {
        if (!valid) {
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        }

        const resp = await this.submitAction();

        if (resp) {
          this.error.title = resp?.title;
          this.error.body = resp?.body;
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        } else {
          if (this.clearFormOnSubmit) {
            this.clearForm(formName);
          }

          this.$alertModal.showSuccess({
            title: this.t("actions.saveChangesSuccess"),
            body: ""
          });
        }
        return true;
      });
    }
  }
});
