
import { defineComponent } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import Location from "@/models/company/Location";
import { ColDef, GridOptions } from "ag-grid-community";
import { AgGridVue } from "ag-grid-vue3";
import Customer, { customerI18nMessages } from "@/models/customer/Customer";
import { useI18n } from "vue-i18n";
import Dinero from "dinero.js";
import { Dinero as DineroType } from "dinero.js";
import { formatDinero } from "@/utils/money";
interface RowData {
  customer: Customer;
  totalSpentAmount: number;
  totalSpentCurrency: "IQD" | "USD";
  totalSpentPrecision: number;
  totalSpentDinero: DineroType;
}
export default defineComponent({
  components: {
    LoadingSpinner,
    AlertBadge,
    AgGridVue
  },

  data() {
    const columnDefs: ColDef[] = [];
    const rowData: RowData[] = [];
    const gridOptions: GridOptions = {};
    const defaultColDef: ColDef = {};
    const messages = {
      en: {
        ...customerI18nMessages.en,
        totalSpentAmount: "Total Spent Amount",
        startDate: "Start Date",
        endDate: "End Date"
      },
      ar: {
        ...customerI18nMessages.ar,
        totalSpentAmount: "مجموع المبلغ المصروف",
        startDate: "Start Date",
        endDate: "End Date"
      }
    };

    const { t } = useI18n({
      messages
    });
    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,
      error: {
        title: "",
        body: ""
      },
      loading: false,
      locations: new Array<Location>(),
      location: new Location(),
      period: [
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        Date()
      ], // the default period is from the first day of the month to the current day
      t
    };
  },

  async beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 100,
      cellStyle: { textAlign: "center" },
      flex: 1
    };
    this.columnDefs = [
      {
        headerName: this.t("customerDisplayName"),
        field: "customer.contactDisplayName",
        checkboxSelection: true,
        autoHeight: true,
        cellStyle: { textAlign: "start" },
        headerCheckboxSelection: true,
        minWidth: 500
      },
      {
        headerName: this.t("totalSpentAmount"),
        field: "totalSpentAmount",
        type: "numericColumn",
        filter: "agNumberColumnFilter",
        valueFormatter: params => {
          const data = params.data as RowData;
          return formatDinero(data.totalSpentDinero);
        },
        cellStyle: { textAlign: "center" }
      }
    ];
    const locationRes = await this.$http.get<Location[]>(Location.ENDPOINT);
    this.locations = locationRes.map(cat => Location.from(cat));
  },

  watch: {
    period: {
      handler: function() {
        this.updateRowData();
      }
    },
    location: {
      handler: function() {
        this.updateRowData();
      }
    }
  },

  methods: {
    async updateRowData() {
      this.gridOptions.api?.showLoadingOverlay();
      //here should formulate the path for the new request depending on the new date and location
      //for now I'm just hitting the customers endpoint
      //this function will be called whenever the location or the period is changed
      try {
        const data = await this.$http.get<Customer[]>(Customer.ENDPOINT);
        //here just generating mock data
        this.rowData = data.map(customer => {
          return {
            customer: Customer.from(customer),
            totalSpentAmount: 1000000,
            totalSpentCurrency: "IQD",
            totalSpentPrecision: 0,
            totalSpentDinero: Dinero({
              amount: 1000000,
              currency: "IQD",
              precision: 0
            })
          };
        });
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        });
      }

      this.gridOptions.api?.hideOverlay();
    },

    async onGridReady() {
      await this.updateRowData();
    }
  }
});
