
import { defineComponent } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import Location from "@/models/company/Location";
import { ColDef, GridOptions } from "ag-grid-community";
import { useI18n } from "vue-i18n";
import dayjs from "dayjs";
import HorizontalButton from "@/components/HorizontalButton.vue";
import Dinero from "dinero.js";
import { formatDinero } from "@/utils/money";
import Receipt from "@/components/Receipt.vue";
import User from "@/models/user/User";

class RegisterClosing {
  static from(json: Record<string, unknown> | RegisterClosing) {
    return Object.assign(new RegisterClosing(), json);
  }
  static ENDPOINT = "reports/sales/register-closing";

  id?: string;
  externalID?: string;
  location?: Location;
  locationID?: string;

  openingDate?: string;
  openedByID?: string;
  openedBy?: User;

  currency?: "IQD" | "USD";
  precision?: number;
  
  lastShiftFloatCash?: number;
  openingFloatCash?: number;

  cashIn?: number;
  refunds?: number;
  addedCash?: number;
  droppedCash?: number;
  totalCash?: number;

  closingDate?: string;
  closedByID?: string;
  closedBy?: User;
  closingFloatCash?: number;

  depositedCash?: number;
  floatCashVariance?: number;
  depositedCashVariance?: number;
  totalCashVariance?: number;

  getDineroFormatFromAmount(amount: number | undefined) {
    return formatDinero(
      Dinero({
        amount: amount,
        precision: this.precision,
        currency: this.currency
      })
    );
  }

  formatTimestamp(timestamp: string | undefined) {
    return dayjs(timestamp).format("YYYY/MM/DD - hh:MM A");
  }
}
export default defineComponent({
  components: {
    LoadingSpinner,
    AlertBadge,
    HorizontalButton,
    Receipt
  },

  data() {
    const columnDefs: ColDef[] = [];
    const registerClosing: RegisterClosing = new RegisterClosing();
    const gridOptions: GridOptions = {};
    const defaultColDef: ColDef = {};
    const messages = {
      en: {
        selectDate: "Select Date",
        selectStore: "Select Store",
        print: "Print",
        store: "Store",
        generateReport: "Generate Report",
        reportTitle: "Register Closing Report",
        openingInfo: {
          title: "Opening Info",
          dateAndTime: "Opening Date & Time",
          openedBy: "Opened By",
          floatCash: "Opening Float Cash",
          lastShiftFloatCash: "Last Shift Float Cash"
        },
        transInfo: {
          title: "Trans Info",
          cashIn: "Cash In +",
          refunds: "Refunds -",
          addedCash: "Added Cash +",
          droppedCash: "Dropped Cash -",
          totalCash: "Total Cash"
        },
        closingInfo: {
          title: "Closing Info",
          dateAndTime: "Closing Date & Time",
          closedBy: "Closed By",
          floatCash: "Closing Float Cash"
        },
        depositInfo: {
          title: "Deposit Info",
          depositedCash: "Deposited Cash"
        },
        variances: {
          title: "Variances",
          floatCash: "Float Cash Variance",
          depositedCash: "Deposited Cash Variance",
          totalCash: "Total Cash Variance"
        }
      },
      ar: {
        selectDate: "Select Date",
        selectStore: "Select Store",
        store: "Store",
        generateReport: "Generate Report",
        reportTitle: "Register Closing Report",
        print: "Print",
        openingInfo: {
          title: "Opening Info",
          dateAndTime: "Opening Date & Time",
          openedBy: "Opened By",
          floatCash: "Opening Float Cash",
          lastShiftFloatCash: "Last Shift Float Cash"
        },
        transInfo: {
          title: "Trans Info",
          cashIn: "Cash In +",
          refunds: "Refunds -",
          addedCash: "Added Cash +",
          droppedCash: "Dropped Cash -",
          totalCash: "Total Cash"
        },
        closingInfo: {
          title: "Closing Info",
          dateAndTime: "Closing Date & Time",
          closedBy: "Closed By",
          floatCash: "Closing Float Cash"
        },
        depositInfo: {
          title: "Deposit Info",
          depositedCash: "Deposited Cash"
        },
        variances: {
          title: "Variances",
          floatCash: "Float Cash Variance",
          depositedCash: "Deposited Cash Variance",
          totalCash: "Total Cash Variance"
        }
      }
    };

    const { t } = useI18n({
      messages
    });
    return {
      columnDefs,
      registerClosing,
      gridOptions,
      defaultColDef,
      error: {
        title: "",
        body: ""
      },
      loading: false,
      locations: new Array<Location>(),
      location: "",
      selectedDate: dayjs().format("YYYY-MM-DD"),
      t
    };
  },

  async beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 100,
      cellStyle: { textAlign: "center" },
      flex: 1
    };
    this.columnDefs = [
      {
        headerName: this.t("name"),
        field: "itemName",
        checkboxSelection: true,
        autoHeight: true,
        cellStyle: { textAlign: "start" },
        headerCheckboxSelection: true,
        minWidth: 500
      },
      {
        headerName: this.t("quantity"),
        field: "quantity",
        type: "numericColumn",
        filter: "agNumberColumnFilter",
        cellStyle: { textAlign: "center" }
      }
    ];
    const locationRes = await this.$http.get<Location[]>(Location.ENDPOINT);
    this.locations = locationRes.map(cat => Location.from(cat));
  },

  methods: {
    print() {
      window.print();
    },
    async updateRowData() {
      if (!this.location) {
        return;
      }

      this.gridOptions.api?.showLoadingOverlay();
      try {
        const data: any = await this.$http.get<RegisterClosing>(`${RegisterClosing.ENDPOINT}?location_id=${this.location}&closing_date=${dayjs(this.selectedDate).format("YYYY-MM-DD")}`);
        this.registerClosing = RegisterClosing.from(data);
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        });
      }
      this.gridOptions.api?.hideOverlay();
    },

    async onGridReady() {
      await this.updateRowData();
    }
  }
});
