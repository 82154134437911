
import { defineComponent } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import Location from "@/models/company/Location";
import ColumnChart from "@/components/charts/ColumnChart.vue";
import { useI18n } from "vue-i18n";
import dayjs from "dayjs";
import HorizontalButton from "@/components/HorizontalButton.vue";

interface ComparisonByNumberOfSalesData {
  dateLabel: string;
  totalSales: number;
  totalReturns: number;
}

interface ComparisonBySalesValueData {
  dateLabel: string;
  currency: string;
  precision: number;
  totalSalesAmount: number;
  totalReturnAmount: number;
  netSalesAmount: number;
}

class ComparisonByNumberOfSales {
  storeName?: string;
  storeID?: string;
  data?: ComparisonByNumberOfSalesData[];
}

class ComparisonBySalesValue {
  storeName?: string;
  storeID?: string;

  data?: ComparisonBySalesValueData[];
}

class SalesComparison {
  static from(json: Record<string, unknown> | SalesComparison) {
    return Object.assign(new SalesComparison(), json);
  }

  static ENDPOINT = "reports/sales/sales-comparison";
  mode?: "year2year" | "month2month" | "day2day";
  comparisonByNumberOfSales?: ComparisonByNumberOfSales[];
  comparisonBySalesValue?: ComparisonBySalesValue[];

  chartXAxis() {
    const months = Array<string>();
    if (this.comparisonByNumberOfSales) {
      this.comparisonByNumberOfSales[0]?.data?.forEach(item => {
        months.push(item.dateLabel);
      });
    }
    return months;
  }

  byValueChartData() {
    const salesByValueChartData = new Array<ApexAxisChartSeries>();
    const returnsByValueChartData = new Array<ApexAxisChartSeries>();
    if (this.comparisonBySalesValue !== undefined) {
      this.comparisonBySalesValue.map(location => {
        const salesStoreData = new Array<number>();
        const returnsStoreData = new Array<number>();
        location.data?.map(item => {
          salesStoreData.push(item.totalSalesAmount);
          returnsStoreData.push(item.totalReturnAmount);
        });
        salesByValueChartData.push(({
          name: location.storeName,
          data: salesStoreData
        } as unknown) as ApexAxisChartSeries);
        returnsByValueChartData.push(({
          name: location.storeName,
          data: returnsStoreData
        } as unknown) as ApexAxisChartSeries);
      });
    }
    return { sales: salesByValueChartData, returns: returnsByValueChartData };
  }
  byNumberChartData() {
    const numberOfSalesChartData = new Array<ApexAxisChartSeries>();
    const numberOfReturnsChartData = new Array<ApexAxisChartSeries>();
    if (this.comparisonByNumberOfSales !== undefined) {
      this.comparisonByNumberOfSales.map(location => {
        const salesStoreData = new Array<number>();
        const returnsStoreData = new Array<number>();
        location.data?.map(item => {
          salesStoreData.push(item.totalSales);
          returnsStoreData.push(item.totalReturns);
        });
        numberOfSalesChartData.push(({
          name: location.storeName,
          data: salesStoreData
        } as unknown) as ApexAxisChartSeries);
        numberOfReturnsChartData.push(({
          name: location.storeName,
          data: returnsStoreData
        } as unknown) as ApexAxisChartSeries);
      });
    }
    return { sales: numberOfSalesChartData, returns: numberOfReturnsChartData };
  }
}
export default defineComponent({
  name: "sales-comparison",
  components: {
    LoadingSpinner,
    AlertBadge,
    ColumnChart,
    HorizontalButton
  },
  data() {
    const salesComparison = new SalesComparison();
    const selectedMode = "";
    const reportModes = [
      {
        label: "Year to year",
        value: "year2year"
      },
      {
        label: "Month to Month",
        value: "month2month"
      },
      {
        label: "Day to Day",
        value: "day2day"
      }
    ];
    const locationsSelected = Array<string>();
    const categories: string[] = [];
    const comparisonMode = "";
    const messages = {
      en: {
        sales: "Sales",
        returns: "Returns",
        byValue: {
          sales: "Sales By Value",
          returns: "Returns By Value"
        },
        byNumber: {
          sales: "Number Of Sales",
          returns: "Number Of Returns"
        },
        startDate: "From",
        endDate: "To",
        modePlaceholder: "Select Comparison Mode",
        storesPlaceholder: "Select Stores",
        generateButton: "Generate Report"
      },
      ar: {
        sales: "Sales",
        returns: "Returns",
        byValue: {
          sales: "Sales By Value",
          returns: "Returns By Value"
        },
        byNumber: {
          sales: "Number Of Sales",
          returns: "Number Of Returns"
        },
        startDate: "From",
        endDate: "To",
        modePlaceholder: "Select Comparison Mode",
        storesPlaceholder: "Select Stores",
        generateButton: "Generate Report"
      }
    };

    const { t } = useI18n({
      messages
    });

    return {
      categories,
      error: {
        title: "",
        body: ""
      },
      loading: false,
      locations: new Array<Location>(),
      location: "",
      period: [dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
      t,
      comparisonMode,
      locationsSelected,
      reportModes,
      selectedMode,
      salesComparison
    };
  },
  async beforeMount() {
    const locationsResp = await this.$http.get<Location[]>(Location.ENDPOINT);
    this.locations = locationsResp.map(location => Location.from(location));
  },

  methods: {
    async updateRowData() {
      try {
        this.loading = true;
        const salesComparisonResp = await this.$http.get<SalesComparison>(
          `${SalesComparison.ENDPOINT}?location_ids=${this.locations[0].id},${
            this.locations[1].id
          }&from_date=${new Date(
            this.period[0]
          ).toISOString()}&to_date=${new Date(
            this.period[1]
          ).toISOString()}&mode=${this.selectedMode}`
        );

        this.salesComparison = SalesComparison.from(salesComparisonResp);
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        });
      }
      this.loading = false;
    }
  }
});
