
import { defineComponent } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import Location from "@/models/company/Location";
import { ColDef, GridOptions } from "ag-grid-community";
import { useI18n } from "vue-i18n";
import dayjs from "dayjs";
import Dinero from "dinero.js";
import { formatDinero } from "@/utils/money";
import Receipt from "@/components/Receipt.vue";

type registerInfo = {
  registerID: string;
  registerName: string;
  totalAmount: number;
  registerStatus: string;
};

type employeeInfo = {
  employeeID: string;
  employeeName: string;
  shifts: {
    from: string;
    to: string;
  }[];
};

type storeData = {
  locationID: string;
  locationName: string;
  totalSalesAmount: number;
  totalDepositedAmount: number;
  totalItemsSold: number;
  registers: registerInfo[];
  employees: employeeInfo[];
};

class EndOfDay {
  static from(json: Record<string, unknown> | EndOfDay) {
    return Object.assign(new EndOfDay(), json);
  }

  static ENDPOINT = "/reports/sales/end-of-day";

  reportDate = "";
  numberOfStores?: number;
  totalSalesAmount?: number;
  currency?: "USD" | "IQD";
  precision?: number;
  totalWorkingEmployees?: number;
  storeData?: storeData[];

  getDineroFormatFromAmount(amount: number | undefined) {
    return formatDinero(
      Dinero({
        amount: amount,
        precision: this.precision,
        currency: this.currency
      })
    );
  }
  formatTimestamp(timestamp: string | undefined) {
    return dayjs(timestamp).format("hh:MM A");
  }
}

export default defineComponent({
  components: {
    LoadingSpinner,
    AlertBadge,
    Receipt
  },
  data() {
    const columnDefs: ColDef[] = [];
    const endOfDay: EndOfDay = {} as EndOfDay;
    const gridOptions: GridOptions = {};
    const defaultColDef: ColDef = {};
    const messages = {
      en: {
        selectDate: "Select Date",
        selectStore: "Select Store",
        print: "Print",
        reportTitle: "End of Day",
        company: {
          name: "Company Name",
          totalStores: "Total Stores",
          totalWorkingEmployees: "Total Working Employees",
          totalSales: "Total Sales"
        },
        store: {
          name: "Store Name",
          totalSales: "Total Sales",
          totalItemsSold: "Total Items Sold",
          totalDepositedAmount: "Total Deposited Amount",
          registers: "Registers",
          employees: "Employees"
        }
      },
      ar: {
        selectDate: "Select Date",
        selectStore: "Select Store",
        print: "Print",
        reportTitle: "End of Day",
        company: {
          name: "Company Name",
          totalStores: "Total Stores",
          totalWorkingEmployees: "Total Working Employees",
          totalSales: "Total Sales"
        },
        store: {
          name: "Store Name",
          totalSales: "Total Sales",
          totalItemsSold: "Total Items Sold",
          totalDepositedAmount: "Total Deposited Amount",
          registers: "Registers",
          employees: "Employees"
        }
      }
    };
    const { t } = useI18n({
      messages
    });
    return {
      columnDefs,
      endOfDay,
      gridOptions,
      defaultColDef,
      error: {
        title: "",
        body: ""
      },
      loading: false,
      locations: new Array<Location>(),
      location: "",
      selectedDate: dayjs()
        .startOf("month")
        .format("YYYY-MM-DD"),
      t
    };
  },
  async beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 100,
      cellStyle: { textAlign: "center" },
      flex: 1
    };
    this.columnDefs = [
      {
        headerName: this.t("name"),
        field: "itemName",
        checkboxSelection: true,
        autoHeight: true,
        cellStyle: { textAlign: "start" },
        headerCheckboxSelection: true,
        minWidth: 500
      },
      {
        headerName: this.t("quantity"),
        field: "quantity",
        type: "numericColumn",
        filter: "agNumberColumnFilter",
        cellStyle: { textAlign: "center" }
      }
    ];
    const locationRes = await this.$http.get<Location[]>(Location.ENDPOINT);
    this.locations = locationRes.map(cat => Location.from(cat));
    this.updateRowData();
  },
  watch: {
    selectedDate: {
      handler: function() {
        this.updateRowData();
      }
    },
    location: {
      handler: function() {
        this.updateRowData();
      }
    }
  },
  methods: {
    print() {
      window.print();
    },
    async updateRowData() {
      this.gridOptions.api?.showLoadingOverlay();

      try {
        const endOfDayResp = await this.$http.get<EndOfDay[]>(
          `${EndOfDay.ENDPOINT}?closing_date=${new Date(
            this.selectedDate
          ).toISOString()}`
        );
        const mockRes = {
          reportDate: "1653986887876",
          numberOfStores: 2,
          totalSalesAmount: 2850,
          currency: "USD",
          precision: 0,
          totalWorkingEmployees: 3,
          storeData: [
            {
              locationID: "abc",
              locationName: "Al-Mnasour",
              totalSalesAmount: 1340,
              totalDepositedAmount: 1300,
              totalItemsSold: 15,
              registers: [
                {
                  registerID: "...",
                  registerName: "Register #1",
                  totalAmount: 450,
                  registerStatus: "closed"
                },
                {
                  registerID: "...",
                  registerName: "Register #2",
                  totalAmount: 600,
                  registerStatus: "closed"
                },
                {
                  registerID: "...",
                  registerName: "Register #3",
                  totalAmount: 600,
                  registerStatus: "open"
                }
              ],
              employees: [
                {
                  employeeID: "...",
                  employeeName: "Ahmed Mohammed",
                  shifts: [
                    {
                      from: "1653986887876",
                      to: "1653986887876"
                    }
                  ]
                },
                {
                  employeeID: "...",
                  employeeName: "Hussein Talal",
                  shifts: [
                    {
                      from: "1653986887876",
                      to: "1653986887876"
                    }
                  ]
                }
              ]
            },
            {
              locationID: "abc",
              locationName: "Al-Karada",
              totalSalesAmount: 1500,
              totalDepositedAmount: 1450,
              totalItemsSold: 15,
              registers: [
                {
                  registerID: "...",
                  registerName: "Register #1",
                  totalAmount: 750,
                  registerStatus: "closed"
                },
                {
                  registerID: "...",
                  registerName: "Register #2",
                  totalAmount: 250,
                  registerStatus: "closed"
                },
                {
                  registerID: "...",
                  registerName: "Register #3",
                  totalAmount: 500,
                  registerStatus: "closed"
                }
              ],
              employees: [
                {
                  employeeID: "...",
                  employeeName: "Ahmed Mohammed",
                  shifts: [
                    {
                      from: "1653986887876",
                      to: "1653986887876"
                    },
                    {
                      from: "1653986887876",
                      to: "1653986887876"
                    }
                  ]
                }
              ]
            }
          ]
        };
        this.endOfDay = EndOfDay.from(mockRes);
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        });
      }
      1;
      this.gridOptions.api?.hideOverlay();
    },
    async onGridReady() {
      await this.updateRowData();
    }
  }
});
