<template>
  <div>
    <el-form
      @submit.prevent="validateSubmit('registerClosing')"
      novalidate
      :model="registerClosing"
      :rules="rules"
      label-position="top"
      ref="registerClosing"
      class="block"
    >
      <div>
        <div class="flex flex-col md:flex-row justify-end">
          <div class="md:w-3/12 m-2">
            <horizontal-button
              :title="$t('actions.saveChanges')"
              isSuccess
              faIcon="save"
              @click.prevent="validateSubmit('itemForm')"
            />
          </div>

          <div class="md:w-3/12 m-2" v-if="enableClearForm">
            <horizontal-button
              :title="$t('actions.clearButton')"
              isDanger
              faIcon="trash"
              @click.prevent="clearForm('itemForm')"
            />
          </div>
        </div>
      </div>

      <!-- @submit errors -->
      <alert-badge
        isDanger
        :title="error.title"
        :body="error.body"
        @dismissed="
          () => {
            error.title = '';
            error.body = '';
          }
        "
      />
      <div class="m-3" style="height: 90vh;">
        <div class="w-1/2  mx-4">
          <el-row gutter="30">
            <el-col class="p-2" :span="4"></el-col>
            <el-col :span="20" class="p-2">
              <el-row>
                <el-col :span="12">store:</el-col>
                <el-col :span="12"> almansor</el-col>
              </el-row>
              <el-row>
                <el-col :span="12">register #</el-col>
                <el-col :span="12">1</el-col>
              </el-row>
              <el-divider />
            </el-col>
          </el-row>

          <el-row gutter="30">
            <el-col class="bg-gray-300 p-6" :span="4">opening</el-col>
            <el-col :span="20" class="p-2">
              <el-row>
                <el-col :span="12" class="flex items-center my-4"
                  >system float cash from last shift closing</el-col
                >
                <el-col :span="12" class="flex items-center my-4 justify-end">{{
                  formatMoney(1234, "USD")
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="12" class="flex items-center my-4"
                  >float cash</el-col
                >
                <el-col :span="12" class="flex items-center my-4"
                  ><money-input
                    id="opening-float-cash"
                    :currency="$ctx.currency"
                    v-model="registerClosing.openingFloatCash"
                    @dinero-created="
                      dinero =>
                        (registerClosing.openingFloatCashDinero = dinero)
                    "
                    ref="openingFloatCash"
                /></el-col>
              </el-row>
              <el-divider />
            </el-col>
          </el-row>

          <el-row gutter="30">
            <el-col class="bg-gray-300 p-6" :span="4">closing</el-col>
            <el-col :span="20" class="p-2">
              <el-row>
                <el-col :span="12" class="flex items-center my-4"
                  >float cash from last shift closing</el-col
                >
                <el-col :span="12" class="flex items-center my-4 justify-end">{{
                  formatMoney(1234, "USD")
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="12" class="flex items-center my-4"
                  >float cash</el-col
                >
                <el-col :span="12" class="flex items-center my-4"
                  ><money-input
                    id="closing-float-cash"
                    :currency="$ctx.currency"
                    v-model="registerClosing.closingFloatCash"
                    @dinero-created="
                      dinero =>
                        (registerClosing.closingFloatCashDinero = dinero)
                    "
                    ref="closingFloatCash"
                /></el-col>
              </el-row>
              <el-row>
                <el-col :span="12" class="flex items-center my-4"
                  >total cash</el-col
                >
                <el-col :span="12" class="flex items-center my-4 justify-end">{{
                  formatMoney(1234, "USD")
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="12" class="flex items-center my-4">
                  deposited cash</el-col
                >
                <el-col :span="12" class="flex items-center my-4"
                  ><money-input
                    id="closing-deposited-cash"
                    :currency="$ctx.currency"
                    v-model="registerClosing.closingDepositedCash"
                    @dinero-created="
                      dinero =>
                        (registerClosing.closingDepositedCashDinero = dinero)
                    "
                    ref="closingDepositedCash"
                /></el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// components
import HorizontalButton from "@/components/HorizontalButton.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import { ElForm } from "element-plus";
import MoneyInput from "@/components/form/MoneyInput.vue";
import { useI18n } from "vue-i18n";
import { Dinero } from "dinero.js";
import { formatMoney } from "@/utils/money";

interface RegisterClosing {
  openingFloatCash: number;
  openingFloatCashCurrency: "IQD" | "USD";
  openingFloatCashPrecision: number;
  openingFloatCashDinero: Dinero;

  closingFloatCash: number;
  closingFloatCashCurrency: "IQD" | "USD";
  closingFloatCashPrecision: number;
  closingFloatCashDinero: Dinero;

  closingDepositedCash: number;
  closingDepositedCashCurrency: "IQD" | "USD";
  closingDepositedCashPrecision: number;
  closingDepositedCashDinero: Dinero;
}

export default defineComponent({
  name: "new-register-closing",

  setup() {
    const messages = {
      en: {},

      ar: {}
    };

    const { t } = useI18n({ messages, useScope: "global" });

    const rules = {
      openingFloatCash: [{ required: true, message: t("validation.required") }],
      closingFloatCash: [{ required: true, message: t("validation.required") }],
      closingDepositedCash: [
        { required: true, message: t("validation.required") }
      ]
    };

    return { t, rules };
  },

  components: {
    HorizontalButton,
    AlertBadge,
    MoneyInput
  },

  data() {
    const registerClosing = {} as RegisterClosing;
    return {
      registerClosing,
      error: { title: "", body: "" },
      clearFormOnSubmit: true,
      enableClearForm: true
    };
  },
  async beforeMount() {
    try {
      console.log("something here");
    } catch (error) {
      this.error.title = error?.title;
      this.error.body = error?.body;
    }
  },
  methods: {
    formatMoney,
    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;

      this.registerClosing = {} as RegisterClosing;
      this.$globalEmitter.emit("money-input-reset");
      form.resetFields();
    },

    async submitAction() {
      try {
        // const url = `${Item.ENDPOINT}/${this.$route.params.id}`;
        // const resp = await this.$http.put<Item>(url, item);
        // this.item = Item.from(resp);
      } catch (error) {
        return error;
      }
    },

    async validateSubmit(formName: string) {
      // attach the price and cost values
      // this.item.listPriceAmount = this.item.listPriceDinero?.getAmount();
      // this.item.listPriceCurrency = this.item.listPriceDinero?.getCurrency();
      // this.item.listPricePrecision = this.item.listPriceDinero?.getPrecision();

      // this.item.salePriceAmount = this.item.salePriceDinero?.getAmount();
      // this.item.salePriceCurrency = this.item.salePriceDinero?.getCurrency();
      // this.item.salePricePrecision = this.item.salePriceDinero?.getPrecision();

      // this.item.costAmount = this.item.costDinero?.getAmount();
      // this.item.costCurrency = this.item.costDinero?.getCurrency();
      // this.item.costPrecision = this.item.costDinero?.getPrecision();

      const form = this.$refs[formName] as typeof ElForm;
      form?.validate(async (valid: boolean) => {
        if (!valid) {
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        }

        const resp = await this.submitAction();

        if (resp) {
          this.error.title = resp?.title;
          this.error.body = resp?.body;
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        } else {
          if (this.clearFormOnSubmit) {
            this.clearForm(formName);
          }

          this.$alertModal.showSuccess({
            title: this.t("actions.saveChangesSuccess"),
            body: ""
          });
        }
        return true;
      });
    }
  }
});
</script>

<style lang="postcss" scoped>
h1 {
  @apply text-lg py-3 font-bold;
}

h2 {
  @apply py-3 font-bold;
}

h3 {
  @apply py-3 font-bold;
}
.el-divider {
  background-color: black;
  margin-block: 1rem;
}
</style>
