
import { defineComponent } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import Location from "@/models/company/Location";
import { useI18n } from "vue-i18n";
import dayjs from "dayjs";
import HorizontalButton from "@/components/HorizontalButton.vue";
import ColumnChart from "@/components/charts/ColumnChart.vue";
import { formatMoney } from "@/utils/money";
import { Currency } from "dinero.js";

class ProfitAndLoss {
  static from(json: Record<string, unknown> | ProfitAndLoss) {
    return Object.assign(new ProfitAndLoss(), json);
  }

  static ENDPOINT = "/reports/accounting/profit-and-loss";

  locationID?: string;
  locationName?: string;
  period?: string;
  fromDate?: Date;
  toDate?: Date;
  currency?: Currency;
  precision = 0;
  totalRevenueAmount?: number;
  costOfGoodsSoldAmount?: number;
  grossProfitAmount?: number;
  totalExpensesAmount?: number;
  expenses?: { expenseName?: string; amount?: number }[];
  hrAmount?: number;
  operatingProfitAmount?: number;
  taxesAmount?: number;
  interestAmount?: number;
  netProfitOrLossAmount?: number;
}

export default defineComponent({
  components: {
    LoadingSpinner,
    AlertBadge,
    HorizontalButton,
    ColumnChart
  },

  data() {
    const profitAndLoss: ProfitAndLoss = {} as ProfitAndLoss;
    const selectedShortcut: "last-month" | "this-month" | "this-quarter" =
      "this-month";
    const selectedExpense: {
      expenseName?: string;
      amount?: number;
    } = profitAndLoss.expenses ? profitAndLoss.expenses[0] : {};
    const messages = {
      en: {
        startDate: "Start Date",
        endDate: "End Date",
        thisMonth: "This Month",
        lastMonth: "Last Month",
        thisQuarter: "This Quarter",
        generateReport: "Generate Report",
        totalRevenueAmount: "Total Revenue",
        costOfGoodsSoldAmount: "CoGS",
        grossProfitAmount: "Gross Profit",
        totalExpensesAmount: "Total Expenses",
        hrAmount: "HR",
        operatingProfitAmount: "Operating Profit",
        netProfitOrLossAmount: "Net Profit/Loss",
        taxesAmount: "Taxes",
        interestAmount: "Interest",
        incomeStatement: "Income Statement",
        expense: "Expense"
      },
      ar: {
        startDate: "Start Date",
        endDate: "End Date",
        thisMonth: "This Month",
        lastMonth: "Last Month",
        thisQuarter: "This Quarter",
        generateReport: "Generate Report",
        totalRevenueAmount: "Total Revenue",
        costOfGoodsSoldAmount: "CoGS",
        grossProfitAmount: "Gross Profit",
        totalExpensesAmount: "Total Expenses",
        hrAmount: "HR",
        operatingProfitAmount: "Operating Profit",
        netProfitOrLossAmount: "Net Profit/Loss",
        taxesAmount: "Taxes",
        interestAmount: "Interest",
        incomeStatement: "Income Statement",
        expense: "Expense"
      }
    };

    const { t } = useI18n({
      messages
    });

    return {
      profitAndLoss,
      selectedShortcut,
      selectedExpense,
      error: {
        title: "",
        body: ""
      },
      loading: false,
      locations: new Array<Location>(),
      location: "",
      period: [
        dayjs()
          .startOf("month")
          .format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD")
      ], // the default period is from the first day of the month to the current day
      t
    };
  },

  async beforeMount() {
    const locationRes = await this.$http.get<Location[]>(Location.ENDPOINT);
    this.locations = locationRes.map(cat => Location.from(cat));
  },

  methods: {
    formatMoney,
    async updateRowData() {
      try {
        const res = await this.$http.get<ProfitAndLoss>(
          `${ProfitAndLoss.ENDPOINT}?location_id=${this.location}&from_date=${this.period[0]}&to_date=${this.period[1]}`
        );
        this.profitAndLoss = ProfitAndLoss.from(res);
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        });
      }
    },

    isLosing(amount: number) {
      return amount < 0;
    },

    getBarColor(field: string) {
      if (field === "totalRevenueAmount") return "#1e88e5";
      if (
        field === "costOfGoodsSoldAmount" ||
        field === "totalExpensesAmount" ||
        field === "costOfGoodsSoldAmount" ||
        field === "taxesAmount"
      )
        return "#f44336";
      if (
        field === "grossProfitAmount" ||
        field === "operatingProfitAmount" ||
        field === "netProfitOrLossAmount"
      ) {
        return this.isLosing(this.profitAndLoss[field]!)
          ? "#f44336"
          : "#4caf50";
      }
      return "#000";
    },

    getChartInfo(
      profitAndLoss: any
    ): {
      chartData: ApexAxisChartSeries;
      xAxis: Array<string>;
      colors: Array<string>;
    } {
      const theWantedFields = [
        "totalRevenueAmount",
        "costOfGoodsSoldAmount",
        "grossProfitAmount",
        "totalExpensesAmount",
        "taxesAmount",
        "operatingProfitAmount",
        "netProfitOrLossAmount"
      ];
      const colors = Array<string>();
      return {
        chartData: theWantedFields.map(field => {
          colors.push(this.getBarColor(field));
          return {
            name: this.t(field),
            data: [profitAndLoss[field]]
          };
        }),
        xAxis: theWantedFields.map(field => this.t(field)),
        colors
      };
    },

    generateReport() {
      this.updateRowData();
    }
  }
});
