import dayjs from "dayjs";

export function getPath(
  endpoint: string,
  locationId: string | undefined,
  period: string[] | undefined = undefined
) {
  return `${endpoint}?location_id=${locationId == undefined ? "" : locationId}${
    period == undefined
      ? ""
      : `&from_date=${dayjs(period[0]).format("YYYY-MM-DD")}&to_date=${dayjs(period[1]).format("YYYY-MM-DD")}`
  }`;
}
